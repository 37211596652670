import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import className from 'classnames';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { compose } from 'utils/compose';
import styles from './NavPanel.module.scss';
import messages from './NavPanel.translations';

const NavPanel: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const handleSidePanelToggle = () => {
    dispatch(GlobalActions.toggleSidePanelNav());
  };
  const handleUserLogout = () => {
    dispatch(GlobalActions.userLogout());
  };

  return (
    <div className={className(styles.root)}>
      <button className={styles.iconButton} onClick={handleSidePanelToggle}>
        <FontAwesomeIcon icon={['far', 'bars']} className={styles.iconButtonIcon} />
      </button>
      <div className={styles.rightContainer}>
        <button className={className(styles.signOutContainer)} onClick={handleUserLogout}>
          <span className={styles.signOutLabel}>{formatMessage(messages.NavPanelSignOut)}</span>
          <FontAwesomeIcon icon={['far', 'sign-out']} className={styles.iconButtonIcon} />
        </button>
      </div>
    </div>
  );
};

export default compose(NavPanel);
