import { Language } from 'types';
import ApiClient from './apiClient';

interface ClosedUserGroup {
  title: string;
  slug: string;
}

export const getClosedUserGroups = async () =>
  ApiClient.get<ClosedUserGroup[]>(`api/closed-user-groups`, {
    apiUrl: process.env.REACT_APP_GOWAGO_URL,
    queryParams: { lang: Language.EN },
  });
