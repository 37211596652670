import clsx from 'clsx';
import Accordion from 'components/Accordion';
import RHFInput from 'components/Forms/RHFInput/RHFInput';
import RHFSelect from 'components/Forms/RHFSelect';
import LoadingIndicator from 'components/LoadingIndicator';
import { CHF } from 'constants/constants';
import { OrderPageState } from 'pages/EditOrderPage/store/initial-state';
import GlobalSelectors from 'pages/_store/global/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { AIOPackageName, OwnershipType, PublicOffer } from 'types';
import { getAioPackageNameOptions } from 'utils/aio-packages';
import { getCantonOptions } from 'utils/cantons';
import { getLocale } from 'utils/local-storage';
import { useStyles } from '../FormContent.styles';
import { messages } from '../FormContent.translations';
import {
  getAgeCategoryOptions,
  getLeasingMileageOptions,
  getLeasingPeriodOptions,
  getNationalityOptions,
  getOwnershipTypeOptions,
} from './OfferLeasingPramsForm.utils';

const OfferLeasingParamsForm: FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { setValue, errors } = useFormContext<PublicOffer>();

  const {
    orderPage: { isFetchingResult, order },
    isSuperAdmin,
    isOfferPricingUpdateAdmin,
  } = useSelector<RootState, { orderPage: OrderPageState; isSuperAdmin: boolean; isOfferPricingUpdateAdmin: boolean }>(
    (state) => ({
      orderPage: state.orderPage,
      isSuperAdmin: GlobalSelectors.getIsSuperAdmin(state),
      isOfferPricingUpdateAdmin: GlobalSelectors.getIsOfferPricingUpdateAdmin(state),
    }),
    shallowEqual
  );
  const { downPayment } = errors || {};

  const orderDeactivated = !!order?.datetimeDeactivated;
  const ownershipType = order?.ownershipType;
  const isLeasingOnly = ownershipType === OwnershipType.LEASING_ONLY;
  const isPrivateCredit = ownershipType === OwnershipType.PRIVATE_CREDIT;
  const isBusinessLeasing = ownershipType === OwnershipType.BUSINESS_LEASING;

  const userLocale = getLocale();

  const aioPackageNameOptions = getAioPackageNameOptions(formatMessage);

  const onAioPackageNameChange = (newAioPackageName: string) => {
    setValue('aioPackageName', newAioPackageName);
  };

  const ownershipTypeOptions = getOwnershipTypeOptions(order, formatMessage);
  const cantonOptions = getCantonOptions(formatMessage);
  const nationalityOptions = getNationalityOptions();
  const ageCategoryOptions = getAgeCategoryOptions(formatMessage);
  const leasingPeriodOptions = getLeasingPeriodOptions(formatMessage, order?.esDeal?.allowedLeasingPeriods);
  const leasingMileageOptions = getLeasingMileageOptions(
    formatMessage,
    userLocale,
    order?.esDeal?.allowedLeasingMileages
  );

  const onOwnershipTypeChange = (newOwnershipType: OwnershipType) => {
    setValue('ownershipType', newOwnershipType);

    const isLeasing = newOwnershipType === OwnershipType.LEASING;
    const aioPackageName = isLeasing ? AIOPackageName.PREMIUM : '';
    setValue('aioPackageName', aioPackageName);
  };

  const onCantonChange = (newCanton: string) => setValue('canton', newCanton);
  const onNationalityChange = (newNationality: string) => setValue('nationality', newNationality);
  const onAgeCategoryChange = (newAgeCategory: string) => setValue('ageCategory', newAgeCategory);
  const onLeasingPeriodChange = (newLeasingPeriod: string) => setValue('leasingPeriod', Number(newLeasingPeriod));
  const onLeasingMileageChange = (newLeasingMileage: string) => setValue('leasingMileage', Number(newLeasingMileage));

  const disabledForPrivateCredit = orderDeactivated || isPrivateCredit;
  const disabledForLeasingOnly = orderDeactivated || isLeasingOnly;
  const disabledForBusinessLeasing = orderDeactivated || isBusinessLeasing;
  const isInterestRateDisabled = (!isSuperAdmin && !isOfferPricingUpdateAdmin) || disabledForPrivateCredit;

  return (
    <div className={clsx('OfferLeasingParamsForm', classes.root)}>
      <LoadingIndicator isLoading={isFetchingResult}>
        <Accordion header={formatMessage(messages.OfferOverviewFormLeasingInformation)} collapsable={false}>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFSelect
                name="ownershipType"
                label={formatMessage(messages.OfferOverviewFormOwnershipType)}
                options={ownershipTypeOptions}
                onChange={onOwnershipTypeChange}
                disabled={disabledForPrivateCredit || disabledForBusinessLeasing}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFSelect
                name="aioPackageName"
                label={formatMessage(messages.OfferOverviewFormAllInOnePackageName)}
                options={aioPackageNameOptions}
                onChange={onAioPackageNameChange}
                disabled={disabledForPrivateCredit || disabledForLeasingOnly || disabledForBusinessLeasing}
              />
            </div>
            <div className={classes.formColumn} />
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFSelect
                name="canton"
                label={formatMessage(messages.OfferOverviewFormCanton)}
                options={cantonOptions}
                onChange={onCantonChange}
                disabled={orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFSelect
                name="nationality"
                label={formatMessage(messages.OfferOverviewFormNationality)}
                options={nationalityOptions}
                onChange={onNationalityChange}
                disabled={
                  orderDeactivated || disabledForPrivateCredit || disabledForLeasingOnly || disabledForBusinessLeasing
                }
              />
            </div>
            <div className={classes.formColumn}>
              <RHFSelect
                name="ageCategory"
                label={formatMessage(messages.OfferOverviewFormAgeCategory)}
                options={ageCategoryOptions}
                onChange={onAgeCategoryChange}
                disabled={
                  orderDeactivated || disabledForPrivateCredit || disabledForLeasingOnly || disabledForBusinessLeasing
                }
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFSelect
                disabled={disabledForPrivateCredit}
                name="leasingPeriod"
                label={formatMessage(messages.OfferOverviewFormLeasingPeriod)}
                options={leasingPeriodOptions}
                onChange={onLeasingPeriodChange}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFSelect
                disabled={orderDeactivated || isPrivateCredit}
                name="leasingMileage"
                label={formatMessage(messages.OfferOverviewFormLeasingMileage)}
                options={leasingMileageOptions}
                onChange={onLeasingMileageChange}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={downPayment}
                startAdornment="CHF"
                type="number"
                name="downPayment"
                integer
                label={formatMessage(messages.OfferOverviewFormDownPayment)}
                disabled={disabledForPrivateCredit}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment={CHF}
                name="leasingRateConfirmed"
                label={formatMessage(messages.OfferOverviewFormLeasingRateConfirmed)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment={CHF}
                name="aioMonthlyRateConfirmed"
                label={formatMessage(messages.OfferOverviewFormAllInOneMonthlyRate)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="promoCode"
                label={formatMessage(messages.OfferOverviewPromoCode)}
                disabled={disabledForBusinessLeasing}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment={CHF}
                name="totalMonthlyPrice"
                label={formatMessage(messages.OfferOverviewFormTotalMonthlyPrice)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment={CHF}
                name="residualValueConfirmed"
                label={formatMessage(messages.OfferOverviewFormResidualValueConfirmed)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="interestRateDiscount"
                label={formatMessage(messages.OfferOverviewInterestRateDiscount)}
                disabled={isInterestRateDisabled}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment={CHF}
                name="extraKmChf"
                label={formatMessage(messages.OfferOverviewFormExtraKMCHF)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="nominalInterestRateOriginal"
                label={formatMessage(messages.OfferOverviewNominalInterestRateOriginal)}
                disabled={isInterestRateDisabled}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="interestRateNominalConfirmed"
                label={formatMessage(messages.OfferOverviewNominalInterestRate)}
                disabled
              />
            </div>
          </div>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};
export default OfferLeasingParamsForm;
