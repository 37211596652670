import { FuelType, FuelTypesDetailed, Make } from '@carcodex/gow-shared-mono/modules/car/car.types';
import { KMType, MonthType } from 'constants/constants';
import { OptionsObject } from 'notistack';
import { ReactNodeArray } from 'react';

export enum Language {
  EN = 'en',
}

export enum ContractLanguage {
  DE = 'de',
  FR = 'fr',
  IT = 'it',
}

export enum HasTradeInType {
  YES = 'YES',
  MAYBE = 'MAYBE',
  NO = 'NO',
}

export type TermPeriod = '6' | '12' | '24' | '36' | '48' | '60' | '72' | '84' | 6 | 12 | 24 | 36 | 48 | 60 | 72 | 84;

export type LeasingPeriod = 12 | 24 | 36 | 48 | 60;
export type LeasingMileage = 10000 | 15000 | 20000 | 25000 | 30000 | 35000 | 40000 | 50000;

export type AboKMType = 850 | 1250 | 1700;
export type AboMonthType = 6 | 12 | 18;

export const availableLenghtsOfLease: LeasingPeriod[] = [12, 24, 36, 48, 60];

export const availableMileage: LeasingMileage[] = [10000, 15000, 20000, 25000, 30000];

export type Locale = 'de-ch' | 'en' | 'fr-ch';
export type LocaleShortLabel = 'DE' | 'EN' | 'FR';
export type LocaleLabel = 'Deutsch' | 'English' | 'Français';

export interface Translation {
  id: string;
  defaultMessage: string;
}

export enum DateFormat {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
}

export interface LocaleConstant {
  [locale: string]: {
    shortLabel: LocaleShortLabel;
    label: LocaleLabel;
    value: Locale;
  };
}

export enum ErrorTypes {
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export enum FolderName {
  PERSONAL_INFORMATION = 'personalInformation',
  TRADE_IN = 'tradeIn',
  TRADE_IN_IMAGES = 'tradeInImages',
  TRADE_IN_TCS = 'tradeInTCSTestResults',
  DEAL = 'deal',
  DEAL_LEASING_CONTRACT = 'dealLeasingContract',
  DEAL_EQUIPMENT = 'dealEquipment',
  DEAL_CARTE_GRISE = 'dealCarteGrise',
  DEAL_PCAS_OPT = 'dealPCASOptConfirmation',
}

export interface CustomerFolders {
  [FolderName.PERSONAL_INFORMATION]: string;
  [FolderName.TRADE_IN]: string;
  [FolderName.TRADE_IN_IMAGES]: string;
  [FolderName.TRADE_IN_TCS]: string;
}

export interface OfferFolders {
  [FolderName.DEAL]: string;
  [FolderName.DEAL_LEASING_CONTRACT]: string;
  [FolderName.DEAL_EQUIPMENT]: string;
  [FolderName.DEAL_CARTE_GRISE]: string;
  [FolderName.DEAL_PCAS_OPT]: string;
}

export type HubspotFolders = CustomerFolders & OfferFolders;

export interface OfferAndCustomerHubspotFolders {
  offerFolders: OfferFolders;
  customerFolders: CustomerFolders;
  rootHubspotFolder: string;
}

export interface CarDealItemProps {
  id: number;
  createdAt: Date;
  updateAt: Date;
  publicId: string;
  status: DealStatus;
  make: Make;
  model: string;
  price: number;
  engine: string;
  imageMain?: ImageFormatUrls;
  vendor: number;
}

export interface DealResultsFilters {
  $limit: number;
  $skip: number;
}

export interface ListingFacet {
  key: string;
  count: number;
  value?: boolean;
}

export interface ListingStatusFacet extends ListingFacet {
  key: DealStatus;
}

export interface ListingSpecialDealTypeFacet extends ListingFacet {
  key: SpecialDealType;
}

export interface DealResultFacets {
  status: ListingStatusFacet[];
  source: ListingFacet[];
  specialDealType: ListingSpecialDealTypeFacet[];
}

export interface DealResultsResponse extends WithPossibleWarning {
  /* total number of records for given filter */
  countFiltered: number;
  /* max number of items per page */
  $limit: number;
  /* number of skipped items (offset) */
  $skip: number;
  /* Total number of cars in database */
  countTotal: number;
  results: CarDealItemProps[];
  facets: DealResultFacets;
}

export interface ApiWarnings {
  downPaymentOutOfBounds: boolean;
}

export interface WithPossibleWarning {
  warning?: ApiWarnings;
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface ImageUploadFile extends File {
  preview: string;
  path?: string;
}

export interface UserInfo {
  id: number;
  email: string;
  status: 'active' | 'inactive' | '';
  firstName: string;
  lastName: string;
  makes: string[];
  roles?: string[];
}

export type Vendor = VendorPublic;

export interface PartnerToken {
  token: string;
  expiryDate: Date;
}

export enum CheckoutContext {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface VendorRequest extends Vendor {
  ownership: OwnershipRequest;
}

export interface VendorFacet {
  key: VendorStatus;
  count: number;
}
export interface VendorQueryResult {
  results: Vendor[];
  facets: {
    status: VendorFacet[];
  };
  countTotal: number;
  countFiltered: number;
}

export interface OrdersQueryResult {
  countTotal: number;
  countFiltered: number;
  offers: OfferForSalesPortal[];
  $skip: number;
  $limit: number;
}

export enum CheckoutStep {
  START_APPLICATION = 'start-application',
  MY_INFORMATION = 'my-information',
  TRADE_IN = 'trade-in',
  REVIEW_ORDER = 'review-order',
  ORDER_PROCESSING = 'order-processing',
  CONTRACT = 'contract',
  SUCCESS = 'success',
}

export enum CheckoutMyInformationSubStep {
  PERSONAL = 'personal',
  FINANCES = 'finances',
  DOCUMENTS = 'documents',
  ELIGIBILITY = 'eligibility',
}

export enum CheckoutTradeInSubSteps {
  CAR_INFORMATION = 'car-information',
  CONDITION = 'car-condition',
  PICTURES = 'pictures',
}

export enum CheckoutOrderProcessingSubStep {
  REVIEWING_ORDER = 'reviewing-order',
  RESERVING_CAR = 'reserving-car',
  PREPARING_CONTRACT = 'preparing-contract',
}

export enum CheckoutContractSubStep {
  EQUIPMENT = 'equipment',
  AIO = 'all-in-one',
  LEASING = 'leasing',
  ABO = 'abo',
}

export type CheckoutSubStepsType =
  | CheckoutMyInformationSubStep
  | CheckoutTradeInSubSteps
  | CheckoutOrderProcessingSubStep
  | CheckoutContractSubStep;

export interface CheckoutStepObject {
  step: CheckoutStep;
  updatedAt?: Date;
  completed: boolean;
  subStep?: CheckoutSubStepsType;
}

export enum OwnershipType {
  LEASING = 'leasing',
  LEASING_ONLY = 'leasing_only',
  PRIVATE_CREDIT = 'private_credit',
  BUSINESS_LEASING = 'business_leasing',
}

export enum AIOPackageName {
  PREMIUM = 'all_in_one_premium',
  ESSENTIALS = 'all_in_one_essential',
  LIGHT = 'all_in_one_light',
}

export type OfferForSalesPortal = {
  customer?: Partial<Customer>;
  aboFirstPayment?: number;
  aboMileage?: AboKMType;
  aboMonthlyRate?: number;
  aboPeriod?: AboMonthType;
  advisor: Partial<Advisor>;
  aioMonthlyRateConfirmed?: number;
  canton?: string;
  carPrice?: number;
  createdAt?: Date;
  dealId: string;
  downPayment?: number;
  engine?: string;
  extraKmChf?: number;
  hubspotDealId: string;
  km?: number;
  leasingPeriod?: MonthType;
  leasingMileage?: KMType;
  leasingRateConfirmed?: number;
  licensingDate?: Date;
  listPrice?: number;
  make?: string;
  model?: string;
  modelYear?: number;
  ownershipType: OwnershipType;
  publicId: string;
  residualValueConfirmed?: number;
  totalMonthlyPrice?: string;
  offerStatus: OfferStatus;
} & Partial<Offer>;

export enum CarCondition {
  LIKE_NEW = 'LIKE_NEW',
  GOOD = 'GOOD',
  AVERAGE = 'AVERAGE',
  POOR = 'POOR',
}

export interface TradeIn {
  mileage?: number;
  isYourCurrent?: boolean;
  tyresCount?: number;
  rimsCount?: number;
  hasServiceBooklet?: boolean;
  carCondition?: CarCondition;
}

export interface CustomerDocument {
  type: FileType;
  id: string;
  name: string;
  inUse: boolean;
  parentFolderId: string;
}

export interface Customer {
  email: string;
  firstName: string;
  lastName: string;
  hubspotId: string;
  phoneNumber: string;
}

export interface Advisor {
  id: number;
  advisorHubspotId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  whatsappNumber: string;
  profilePicture?: string;
  default: boolean;
}

export interface Offer {
  hubspotDealId?: string;
  hubspotCreatedAt?: Date;
  hubspotUpdatedAt?: Date;
  leasingBank?: LeasingProvider;
  leasingPeriod?: MonthType;
  leasingMileage?: KMType;
  aboPeriod?: AboMonthType;
  aboMileage?: AboKMType;
  ownershipType?: OwnershipType;
  downPayment?: number;
  canton?: string;
  leasingRateConfirmed?: number;
  listPrice?: number;
  carPrice?: number;
  interestRateNominalConfirmed?: number;
  residualValueConfirmed?: number;
  extraKmChf?: number;
  aioMonthlyRateConfirmed?: number;
  aboMonthlyRate?: number;
  aboFirstPayment?: number;
  aboRegistrationFee?: number;
  totalMonthlyPrice?: number;
  accessToken?: string;
  pandadocCustomerLink?: string;
  pandadocAboContractLink?: string;
  pandadocInternalLink?: string;
  pandadocId?: string;
  pandadocCreatedAt?: Date;
  pandadocLastUpdate?: Date;
  pandadocExpiryDate?: Date;
  pandadocContractNumber?: string;
  publicId?: string;
  mibaLeasingContractNumber?: string;
  offerPageLink?: string;
  tyreDimensionsSummer?: string;
  tyreDimensionsWinter?: string;
  make?: string;
  model?: string;
  modelSlug?: string;
  modelYear?: number;
  engine?: string;
  licensingDate?: string;
  km?: number;
  vehicleType: ConditionType;
  colorOutside: string;
  typenschein: string;
  vehicleNumber: string;
  dealId: string;
  auditOpenleaseRequest: string;
  auditOpenleaseResponse: string;
  openleaseApplicationId: string;
  lastUserInteractionIP?: string;
  lastJWT?: string;
  tradeInRequested?: boolean;
  userDeviceId: string;
  userDeviceSessionId: string;
  promoCode?: string;
  isEquipmentContractReady?: boolean;
  equipmentContractAcceptedDatetime?: Date;
  isLeasingContractReady?: boolean;
  intrumIdentificationLink?: string;
  areContractsReady?: boolean;
  isAIOContractSigned?: boolean;
  isABOContractSigned?: boolean;
  isLeasingContractSigned?: boolean;
  contractSignedAboEmailTimeSent?: Date;
  contractSignedLeasingEmailTimeSent?: Date;
  contractReminderEmailTimeSent?: Date;
  contractSignedAllInOneEmailTimeSent?: Date;
  contractEmailTimeSent?: Date;
  orderProcessingCarReservedEmailTimeSent?: Date;
  orderProcessingPassedDocAndCrifEmailTimeSent?: Date;
  orderProcessingAboEmailTimeSent?: Date;
  leasingOrderProcessingEmailTimeSent?: Date;
  orderStartedAboEmailTimeSent?: Date;
  leasingOrderStartedEmailTimeSent?: Date;
  monthlyDiscount: number;
  deal?: Deal;
  customer?: Customer;
  vendor?: Vendor;
  advisor?: Advisor;
}

export interface Seller {
  id: number;
  hubspotId: number;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  makes?: Make[];
  status: SellerStatus;
  deleted?: Date;
  roles: RolesEnum[];
  vendors: Vendor[];
  createdAt: Date;
  updatedAt: Date;
  dataVersion: number;
}

export enum SellerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DROPPED = 'dropped',
  PENDING_INVITE = 'pending_invite',
}

export enum RolesEnum {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  OFFER_PRICING_UPDATE_ADMIN = 'offer_pricing_update_admin',
}

export interface VendorQuery {
  vendorStatus?: VendorStatus[];
  name?: string;
  sorting: VendorSorting;
  $limit: number;
  $skip: number;
}

export interface OrdersQuery {
  query?: string;
  $limit: number;
  $skip: number;
}

export interface VendorOrdersQuery {
  vendorId: number;
  query?: string;
  $limit: number;
  $skip: number;
}

export interface UpdateOrderQuery {
  publicId: string;
  data: PublicOfferPricing;
}

export interface NotificationProps extends OptionsObject {
  message: string;
}

export interface NotificationPropsExtended extends NotificationProps {
  id: string;
}

export interface APIError {
  statusCode: number;
  message: string | object;
  error: string;
  data: any;
}
export interface ErrorValidationListFields {
  [field: string]: string | ReactNodeArray;
}

export interface FilterListingsDto {
  make?: Make;
  modelSlug?: string;
  modelYear?: number;
  typenschein?: string;
}

export type CarMakesResponse = Record<Make, CarModel[]>;

export interface CarModel {
  slug: string;
  model: string;
}

export interface CarMake {
  countModels: number;
  makeSlug: string;
  makeWritten: string;
  image: string;
  makeIdSEA: string;
  models?: CarMakeModelsResponse;
  dealCount: number;
}

export interface CarMakeModelsResponse {
  [name: string]: CarMakeModel;
}

export interface CarMakeModel {
  model: string;
  dealCount: number;
  modelIdSEA: string;
}

export const possibleMonthValues: MonthType[] = [12, 24, 36, 48, 60];

export const possibleKMValues: KMType[] = [10000, 15000, 20000, 25000, 30000, 35000, 40000, 50000];

export type ResidualValue = ResidualValuePublic & {
  isNewEntry?: boolean;
};

export enum StartTimeOfWarranty {
  LICENSING = 'from_licensing_start',
  TAKE_OVER = 'from_take_over',
}

export interface Listing {
  id?: string;
  dataVersion?: number;
  typenschein?: string;
  vehicleNumber?: string;
  chassisNumber?: string;
  vendor?: Vendor | number;
  vendorHubspotId?: number;
  source?: SourceType;
  sourceCreated?: Date;
  sourceUpdated?: Date;
  externalId?: string;
  externalUrl?: string;
  title?: string;
  make?: Make;
  model?: string;
  modelYear?: number;
  modelSlug?: string;
  natCode?: number;
  engine?: string;
  trim?: string;
  listPrice?: number;
  price?: number;
  priceHistory?: PriceHistoryElement[];
  fuelType?: FuelType;
  fuelTypeDetailed?: FuelTypesDetailed;
  transmissionDetailed?: TransmissionDetailed;
  drivenWheels?: DrivenWheels;
  seats?: number;
  doors?: number;
  hp?: number;
  cylinder?: number;
  cylinderCapacity?: number;
  curbWeight?: number;
  towingCapacity?: number;
  trunkCap?: number;
  co2Emissions?: number;
  co2EmissionsFromFuelAndElectricity?: number;
  co2EmissionsAverageOfAllSoldNewVehicles?: number;
  energyEfficiency?: ConsumptionRating;
  euroNorm?: PollutionNorm;
  consumptionCity?: number;
  consumptionCountryside?: number;
  consumptionTotal?: number;
  consumptionElectric?: number;
  consumptionGasCity?: number;
  consumptionGasCountryside?: number;
  consumptionGasTotal?: number;
  consumptionPower?: number;
  batteryRange?: number;
  batteryCapacity?: number;
  petrolEquivalent?: number;
  colorOutside?: string;
  colorOutsideHEX?: string;
  colorOutsideCode?: number;
  colorOutsideManuId?: number;
  colorInside?: string;
  segments?: Segments[];
  carUsage?: CarUsage[];
  licensing?: string;
  licensingDate?: Date;
  conditionTypeDetailed?: ConditionType;
  km?: number;
  greyImport?: boolean;
  mfk?: boolean;
  warranty?: boolean;
  warrantyText?: string[];
  warrantyMonthsLeft?: number;
  warrantyKMLeft?: number;
  warrantyInKM?: number | null;
  warrantyInMonths?: number | null;
  warrantyUntilDate?: Date;
  warrantyStartType?: StartTimeOfWarranty;
  handicappedAccessible?: boolean;
  forExport?: boolean;
  racingCar?: boolean;
  tuning?: boolean;
  accidentVehicle?: boolean;
  lastCheck?: Date | null;
  commentHTML?: string;
  options?: OptionName[];
  optionsExtended?: OptionExtended[];
  optionsDetailed?: OptionsDetailed;
  imageMain?: ImageFormatUrls;
  images?: ImageGalleryElement[];
  tcsTCODataFromCarsMatch?: TCOData;
  tcsTCOData?: TCOData;
  tcsTCODataMatchDate?: Date;
  fsl?: FSLData;
  fslLastUpdate?: Date;
  hasExtraTyres?: boolean;
  mssError?: string;
  carLocation?: Omit<Partial<CarLocation>, 'lan' & 'lon'> | null;
  deliveredIn?: DeliveredIn;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum LeasingProvider {
  MIGROS_BANK = 'migros_bank',
}

export enum ContractType {
  CONTRACT_EXTENSION = 'contract_extension',
}

export enum SpecialDealType {
  HOT_DEAL = 'hot_deal',
  DEAL_OF_THE_MONTH = 'deal_of_the_month',
  FEATURED = 'featured',
  GOWAGO_SALE = 'gowago_sale',
  FLEET_DISCOUNT = 'fleet_discount',
}

export interface CarLocation {
  street: string;
  city: string;
  zip: number;
  lat?: string;
  lon?: string;
}

export interface CarImage {
  small: string;
  medium: string;
  thumbnail: string;
  original: string;
  prefetch: string;
}

export interface KMGeneric<T> {
  '10000'?: T;
  '15000'?: T;
  '18000'?: T;
  '20000'?: T;
  '25000'?: T;
  '30000'?: T;
  '35000'?: T;
  '40000'?: T;
  '50000'?: T;
}

export interface MonthKMPricesGeneric<T> {
  '12'?: KMGeneric<T>;
  '24'?: KMGeneric<T>;
  '36'?: KMGeneric<T>;
  '48'?: KMGeneric<T>;
  '60'?: KMGeneric<T>;
}

export type AllowedLeasingConfiguration = MonthKMPricesGeneric<KMType[]>;

export type Deal = DealPublic & {
  isSelectedCar?: boolean;
  orderGowagoSale?: number;
  orderFeatured?: number;
  defaultLeasingPeriod?: LeasingPeriod;
  defaultLeasingMileage?: LeasingMileage;
  defaultDownPayment?: number;
  specialDealType?: Array<SpecialDealType>;
  closedUserGroups?: Array<string>;
  contractType?: ContractType;
  meta: {
    allowedLeasingConfiguration: AllowedLeasingConfiguration;
    validLeasingPeriod: MonthType;
    validLeasingMileage: KMType;
  };
};

export interface DealRequest {
  publicId?: string;
  legacyId?: number;
  status?: DealStatus;
  deleted?: Date;
  deleteReason?: DeleteReason;
  performanceScore?: number;
  efficiencyScore?: number;
  dealPopularityScore?: number;
  aToZIterator?: number;
  numIdenticalListings?: number;
  featured?: number;
  specialDealType?: Array<SpecialDealType>;
  isLockedToSource: boolean;
  isOwnershipDefault: boolean;
  leasingProvider?: LeasingProvider;
  leasingInterestRate?: number;
  businessLeasing?: boolean;
  fee?: number;
  isResidualValueDefault: boolean;
  residualValues?: ResidualValue[];
  listing: Listing;
  orderGowagoSale?: number | null;
  orderFeatured?: number | null;
  defaultLeasingPeriod?: number | null;
  defaultLeasingMileage?: number | null;
  defaultDownPayment?: number | null;
  closedUserGroups?: Array<string> | null;
  dealerDeliveryFee?: number;
  contractType?: ContractType;
}

export type CarSegment = '' | 'sedan' | 'wagon' | 'smallCar' | 'convertible' | 'suv' | 'van' | 'pickup' | 'sportsCar';

export interface OptionsDetailedCarModels {
  de: {
    standard: string[];
    optional: string[];
  };
  fr: {
    standard: string[];
    optional: string[];
  };
}

export type FuelTypeDetailed =
  | ''
  | 'petrol'
  | 'diesel'
  | 'hybrid'
  | 'hybrid_petrol'
  | 'hybrid_diesel'
  | 'electric'
  | 'CNG_petrol'
  | 'CNG'
  | 'E85'
  | 'LPG'
  | 'H2'
  | 'full_hybrid_diesel'
  | 'full_hybrid_petrol'
  | 'plug_in_hybrid_petrol'
  | 'plug_in_hybrid_diesel'
  | 'mild_hybrid_petrol'
  | 'mild_hybrid_diesel';

export interface Dealer {
  name: string;
  language: Language;
  dealerType?: string;
  leadEmail?: string | null;
  phone: string | null;
  street: string;
  city: string;
  zip: number;
  location?: { lat: number; lon: number };
  openingHoursMondayToThursday?: string[];
  openingHoursMondayToFriday: string[] | null;
  dealerLogo?: CarImage;
  openingHoursFriday?: string[];
  openingHoursSaturday?: string[];
  openingHoursSunday?: string[];
  openingTimesGoogleMaps?: OpeningTimesGoogleMap;
  makes?: string[];
  distance?: number;
  placeId?: string;
  ratingGoogle?: number;
  numberOfGoogleMapsReviews?: number;
  slug: string;
  dealerId: string;
}

export interface OpeningTimesGoogleMap {
  sunday: OpeningDayTimesGoogle[];
  monday: OpeningDayTimesGoogle[];
  tuesday: OpeningDayTimesGoogle[];
  wednesday: OpeningDayTimesGoogle[];
  thursday: OpeningDayTimesGoogle[];
  friday: OpeningDayTimesGoogle[];
  saturday: OpeningDayTimesGoogle[];
}
export interface OpeningDayTimesGoogle {
  open: string;
  close: string;
}

export type AboPeriod = '6' | '12' | '18' | 6 | 12 | 18;
export type AboMileage = '850' | '1250' | '1700' | 850 | 1250 | 1700;

export interface AioPeriodMonthlyCosts {
  '12'?: number;
  '24'?: number;
  '36'?: number;
  '48'?: number;
  '60'?: number;
}

export interface AioMileageMonthlyCosts {
  '10000'?: number;
  '15000'?: number;
  '20000'?: number;
  '25000'?: number;
  '30000'?: number;
  '35000'?: number;
  '40000'?: number;
  '50000'?: number;
}

export type AboMileageMonthlyCosts = Record<AboMileage, number>;
export type AboPeriodMonthlyCosts = Record<AboPeriod, number>;

export interface AllConfigCombinationsMonthlyCosts {
  '12'?: AioMileageMonthlyCosts;
  '24'?: AioMileageMonthlyCosts;
  '36'?: AioMileageMonthlyCosts;
  '48'?: AioMileageMonthlyCosts;
  '60'?: AioMileageMonthlyCosts;
}

export interface DealPricing {
  listPrice: number;
  price: number;
  residualValue: number;
  downPayment: number;
  nominalInterestRate: number | string;
  effectiveInterestRate: number;
  firstLeasingPayment: number;
  firstTotalPayment: number;
  leasingRate: number;
  aioRate: number;
  totalMonthlyRate: number;
  dealerDeliveryFee?: number;
  aboMonthlyRate: number;
  aboFirstPayment: number;
  aboRegistrationFee: number;
  packages: Record<AIOPackageName, number>;
}

export interface CheckoutData {
  leasingRate?: number;
  fsl?: number;
  totalCost: number;
}

export interface FullServiceLeasingComparisonData {
  insurance: number;
  taxes: number;
  service: number;
  tyres: number;
  vignette: number;
  totalYearlyCost: number;
  totalMonthlyCost: number;
  upfrontCost: number;
  tyreChangeCost: number;
  hasExtraTyres: boolean;
  warrantyExisting?: {
    warrantyText: string[];
    warrantyMonthsLeft: number;
    warrantyKMLeft?: number | null;
  };
}

export interface FullServiceLeasingData {
  totalYearlyCost: number;
  totalMonthlyCost: number;
  upfrontCost: number;
  totalMonthlyCostWithLeasing: number;
  canton: string;
  comparison: FullServiceLeasingComparisonData;
  allowedMonths: string[];
  allowedKm: string[];
}

export interface MinPossibleFSLConfiguration {
  minMonth?: number;
  minKM?: number;
}

export interface DefaultLeasingParams {
  canton: string;
  downPayment: number;
  leasingPeriod: LeasingPeriod;
  leasingMileage: LeasingMileage;
  aboMileage?: AboMileage;
  aboPeriod?: AboPeriod;
  ownershipType?: OwnershipType;
}

export interface NumberRangeObject {
  min: number;
  max: number;
}

export interface TcoTimelineInclusionRange {
  total: NumberRangeObject;
  yearly: NumberRangeObject;
  monthly: NumberRangeObject;
}
export interface TcoTimelineInclusionSingleValue {
  total: number;
  yearly: number;
  monthly: number;
}

export interface TcoTimelineData {
  tyres?: TcoTimelineInclusionRange;
  service?: TcoTimelineInclusionRange;
  insurance?: TcoTimelineInclusionRange;
  warranty?: TcoTimelineInclusionRange;
  taxes?: TcoTimelineInclusionSingleValue;
  vignette?: TcoTimelineInclusionSingleValue;
}

export interface DealDiscount {
  percentage: number;
  inCurrency: number;
}

export interface EsDeal {
  id: string;
  hp: number | null;
  consumptionTotal: number | null;
  modelDE: string | null;
  modelFR: string | null;
  consumptionElectric: number | null;
  typenschein: string | null;
  transmissionDetailed: string;
  seats: number | null;
  energyEfficiency: string;
  consumptionCity: number | null;
  provider: string | null;
  segments: CarSegment[];
  euroNorm: string | null;
  consumptionGas?: number;
  optionsDetailed: OptionsDetailedCarModels | null;
  transmission: Transmission;
  colorOutside: string;
  colorOutsideCode?: number;
  engine: string;
  price: number;
  cylinderCapacity: number | null;
  colorInside: string | null;
  warranty: boolean;
  vehicleNumber: string;
  optionsExtended: string[];
  model: string;
  modelSlug: string;
  modelYear: number;
  numberOfViews: number;
  make: string;
  makeWritten: string;
  makeSlug: string;
  co2Emissions: number | null;
  vehicleType: ConditionType;
  licensingDate: string | null;
  licensingDateRaw: string | null;
  licensing: string;
  externalUrl: string;
  km: number | null;
  doors: number | null;
  co2EmissionsFromFuelAndElectricity: number | null;
  chassisNumber: string | null;
  fuelTypeDetailed: FuelTypeDetailed;
  fuelType: FuelType;
  drivenWheels: DrivenWheels;
  curbWeight: number | null;
  towingCapacity: number | null;
  dealer: Dealer;
  trunkCap: number | null;
  maxDownPaymentRelative?: number;
  cylinder: number | null;
  specialDealType: Array<SpecialDealType> | null;
  co2EmissionsAverageOfAllSoldNewVehicles: number | null;
  consumptionCountryside: number | null;
  listPrice: number | null;
  downPayment: number;
  leasingRate: number;
  colorOutsideHEX?: string;
  numIdenticalListings: number | null;
  allowedLeasingPeriods?: LeasingPeriod[];
  allowedLeasingMileages?: LeasingMileage[];
  allowedAboMileages?: AboMileage[];
  allowedAboPeriods?: AboPeriod[];
  allConfigCombinationsMonthlyCosts: AllConfigCombinationsMonthlyCosts;
  aioMonthlyPeriodsCosts?: AioPeriodMonthlyCosts;
  aboMonthlyPeriodsCosts?: AboPeriodMonthlyCosts;
  leasingOnlyMonthlyPeriodsCosts?: AioPeriodMonthlyCosts;
  pricing: DealPricing;
  aioMonthlyMileagesCosts?: AioMileageMonthlyCosts;
  aboMonthlyMileagesCosts?: AboMileageMonthlyCosts;
  leasingOnlyMonthlyMileagesCosts?: AioMileageMonthlyCosts;
  businessLeasing: boolean | null;
  minDownPayment: number;
  minDownPaymentRelative: number;
  maxDownPayment: number;
  modelIdSEA: string;
  makeIdSEA: string;
  leasingInterestRate: number | null;
  effectiveInterestRate: number | null;
  residualValue: number | null;
  tco: TCOData;
  checkout: CheckoutData;
  fsl?: FullServiceLeasingData;
  isCantonInferred?: boolean;
  canton: string;
  isFSLAvailable: boolean;
  minPossibleFSLConfiguration?: MinPossibleFSLConfiguration;
  discountInCHF?: number;
  greyImport?: boolean;
  closedUserGroups?: Array<string>;
  defaultLeasingParams?: DefaultLeasingParams;
  tcoTimeline?: TcoTimelineData;
  createdAt: Date;
  availableOwnership: OwnershipType[];
  deliveredIn: DeliveredIn;
  discount?: DealDiscount;
  images:
    | {
        images: CarImage | null;
        position: number;
      }[]
    | null;
  imageMain?: CarImage;
}

export enum DeliveryTimeUnit {
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export interface DeliveredIn {
  min: number;
  max: number;
  unit: DeliveryTimeUnit;
}

export type Ownership = {
  ownershipType: OwnershipType;
  leasingProvider: LeasingProvider;
  leasingInterestRateNewCar: number;
  leasingInterestRateUsedCar: number;
  leasingInterestRateByMake?: Array<Partial<MakeInterestRate>>;
  businessLeasing?: boolean;
  fee?: number;
};

export type InterestRatesByMakeError = {
  message: string;
  index: number;
};

export interface OwnershipRequest {
  leasingProvider?: LeasingProvider;
  leasingInterestRateNewCar: number;
  leasingInterestRateUsedCar: number;
  leasingInterestRateByMake?: MakeInterestRate[];
  businessLeasing?: boolean;
  fee?: number;
}

export interface DealOwnership {
  leasingProvider: LeasingProvider;
  leasingInterestRate: number;
  businessLeasing?: boolean;
  fee?: number;
}

export interface DefaultDealOwnershipRequest {
  vendorId: number;
  make?: Make;
  vehicleType?: ConditionType;
}

export interface DefaultResidualValuesRequest {
  vendorId: number;
  leasingProvider?: LeasingProvider;
  dealId?: number;
  make?: Make;
  modelSlug?: string;
  natCode?: number;
  fuelType?: FuelType;
  transmission?: Transmission;
  drivenWheels?: DrivenWheels;
  vehicleType?: ConditionType;
}

export interface UpdateDealResponse {
  data?: Deal;
  status: DealUpdateStatuses;
  errors?: ErrorObject[];
}

export interface ErrorObject {
  statusCode: number;
  error: GowagoError;
  message: object | string;
}

// pull it from types repo once it gets live on backend
export enum GowagoError {
  MISSING_FIELDS = 'MissingFields',
  IDENTICAL_ENTITY = 'IdenticalEntity',
  NOT_FOUND = 'NotFound',
  EMPTY_RESIDUAL_VALUES = 'EmptyResidualValues',
}

export enum DealUpdateStatuses {
  UPDATED = 'updated',
  FAILED = 'failed',
}

export interface SellerPublic {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  status: SellerStatus;
}

export interface ImageFormatUrls {
  small: string;
  medium: string;
  original: string;
  prefetch: string;
  thumbnail: string;
}

export interface ImageFormatUrlsOptional {
  small?: string;
  medium?: string;
  original?: string;
  prefetch?: string;
  thumbnail?: string;
}
export interface ImageGalleryElement {
  position: number;
  images: ImageFormatUrls | ImageFormatUrlsOptional;
  hash?: string;
  name?: string;
  key?: string;
}

export enum DealStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ERROR = 'error',
  TRASH = 'trash',
}

export type SortingOrder = 'ASC' | 'DESC';
export enum SortingOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum VendorStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DROPPED = 'dropped',
  TESTING = 'testing',
}

export enum ConditionType {
  NEW = 'new',
  NEW_SINGLE_DAY_REGISTRATION = 'new_single_day_registration',
  USED = 'used',
  DISPLAY_MODEL = 'display_model',
  OLD_TIMER = 'old_timer',
}

export enum DrivenWheels {
  REAR = 'rear',
  FRONT = 'front',
  '4X4' = '4x4',
}

export enum Transmission {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}
export enum TransmissionDetailed {
  AUTOMATIC = 'automatic',
  AUTOMATIC_SEQUENTIAL = 'automatic_sequential',
  AUTOMATIC_STEPLESS = 'automatic_stepless',
  AUTOMATIC_STEPLESS_SEQUENTIAL = 'automatic_stepless_sequential',
  AUTOMATIC_MANUAL = 'automatic_manual',
  MANUAL = 'manual',
  MANUAL_SEQUENTIAL = 'manual_sequential',
}

export enum ResidualValueCalculation {
  ON_PRICE = 'on_price',
  ON_LIST_PRICE = 'on_list_price',
}

export enum Segments {
  CONVERTIBLE = 'convertible',
  SUV = 'suv',
  WAGON = 'wagon',
  SEDAN = 'sedan',
  VAN = 'van',
  SMALL_CAR = 'smallCar',
  PICKUP = 'pickup',
  SPORTS_CAR = 'sportsCar',
}

export enum ConsumptionRating {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}
export enum PollutionNorm {
  EURO_1 = 'Euro 1',
  EURO_2 = 'Euro 2',
  EURO_3 = 'Euro 3',
  EURO_4 = 'Euro 4',
  EURO_5 = 'Euro 5',
  EURO_6 = 'Euro 6',
  EURO_6A = 'Euro 6a',
  EURO_6B = 'Euro 6b',
  EURO_6C = 'Euro 6c',
  EURO_6D = 'Euro 6d',
  EURO_6D_TEMP = 'Euro 6d-TEMP',
}

export enum OptionExtended {
  ALLOY_WHEELS = 'alloy_wheels',
  TRAILER_HITCH = 'trailer_hitch',
  BLUETOOTH_INTERFACE = 'bluetooth_interface',
  DAB_RADIO = 'dab_radio',
  DIFFERENTIAL_LOCK = 'differential_lock',
  DISTANCE_CONTROLLER = 'distance_controller',
  ELECTRIC_SEAT_ADJUSTMENT = 'electric_seat_adjustment',
  HANDSFREE = 'handsfree',
  HARDTOP = 'hardtop',
  ISOFIX = 'isofix',
  AIR_CONDITIONING = 'air_conditioning',
  AUTOMATIC_AIR_CONDITIONING = 'automatic_air_conditioning',
  MANUAL_AIR_CONDITIONING = 'manual_air_conditioning',
  BEND_LIGHTING = 'bend_lighting',
  AIR_SUSPENSION = 'air_suspension',
  NAVIGATION_SYSTEM = 'navigation_system',
  BUILT_IN_NAVIGATION_SYSTEM = 'built_in_navigation_system',
  PORTABLE_NAVIGATION_SYSTEM = 'portable_navigation_system',
  PANORAMA_ROOF = 'panorama_roof',
  PARKING_AID = 'parking_aid',
  PARKING_SENSORS_IN_THE_BACK = 'parking_sensors_in_the_back',
  PARKING_SENSORS_FRONT = 'parking_sensors_front',
  REAR_VIEW_CAMERA = 'rear_view_camera',
  HEADLIGHTS = 'headlights',
  LED_HEADLIGHTS = 'led_headlights',
  XENON_HEADLIGHTS = 'xenon_headlights',
  LASER_HEADLIGHTS = 'laser_headlights',
  SUNROOF = 'sunroof',
  KEYLESS_ACCESS_START = 'keyless_access_start',
  SEAT_VENTILATION = 'seat_ventilation',
  SEAT_COVERS = 'seat_covers',
  SEAT_COVER_ALCANTARA = 'seat_cover_alcantara',
  SEAT_COVER_LEATHER = 'seat_cover_leather',
  SEAT_COVER_FABRIC = 'seat_cover_fabric',
  SEAT_COVER_PARTIAL_LEATHER = 'seat_cover_partial_leather',
  SEAT_HEATING = 'seat_heating',
  SPORTS_SEATS = 'sports_seats',
  LANE_KEEPING_ASSISTANT = 'lane_keeping_assistant',
  STABILITY_CONTROL = 'stability_control',
  PARKING_HEATER = 'parking_heater',
  START_STOP_SYSTEM = 'start_stop_system',
  CRUISE_CONTROL = 'cruise_control',
  BLIND_SPOT_ASSISTANT = 'blind_spot_assistant',
  EIGHT_TIRES = 'eight_tires',
  ROOF_RACK = 'roof_rack',
  DOG_LATTICE = 'dog_lattice',
}

export declare enum CarUsage {
  FAMILY_CAR = 'familyCar',
  DRIVING_FOR_FUN = 'drivingForFun',
  BIG_BOOT = 'bigBoot',
  LONG_JOURNEY = 'longJourney',
  CITY_DRIVING = 'cityDriving',
  COMPANY_CAR = 'companyCar',
  '4X4' = '4x4',
  NEW_DRIVER = 'newDriver',
}
export declare enum OptionName {
  PARKING_360_CAM = 'parking360Cam',
  USB_PLUG = 'usbPlug',
  BLUETOOTH = 'bluetooth',
  NAVIGATION = 'navigation',
  PARKING_ASSISTANT = 'parkingAssistant',
  PARKING_DISTANCE_REAR = 'parkingDistanceRear',
  LANE_ASSISTANT = 'laneAssistant',
  REAR_CAMERA = 'rearCamera',
  CHILD_SEAT_MOUNT = 'childSeatMount',
  DRIVER_HEATING_SEAT = 'driverHeatingSeat',
  DEAD_ANGLE_WARNING = 'deadAngleWarning',
  CRUISE_CONTROL = 'cruiseControl',
  FULL_LEATHER_SEATS = 'fullLeatherSeats',
  FAST_CHARGING = 'fastCharging',
  GLASS_ROOF = 'glassRoof',
}

export enum SourceType {
  AS24 = 'as24',
  MANUAL = 'manual',
}

export enum DeleteReason {
  MANUALLY_REMOVED = 'manually_removed',
  LISTING_REMOVED = 'listing_removed',
  FILTERED_OUT = 'filtered_out',
  DEALER_STATUS_CHANGED = 'dealer_status_changed',
}

export interface MakeInterestRate {
  make: Make;
  leasingInterestRateUsedCar?: number;
  leasingInterestRateNewCar?: number;
}

export interface Day {
  open?: string;
  close?: string;
}

export interface DealSorting {
  name?: SortingOrder;
  status?: SortingOrder;
  price?: SortingOrder;
  createdAt?: SortingOrder;
  makeAndModel?: SortingOrder;
}

export interface VendorSorting {
  hubspotId?: SortingOrder;
  name?: SortingOrder;
  vendorStatus?: SortingOrder;
  countTotalDeals?: SortingOrder;
  countErrors?: SortingOrder;
}

export interface PriceHistoryElement {
  price: number;
  datetime: Date;
}

export interface LanguageOptionDetailed {
  standard: string[];
  optional: string[];
}

export interface OptionsDetailed {
  de: LanguageOptionDetailed;
  fr: LanguageOptionDetailed;
}

export interface KM {
  '10000'?: number;
  '15000'?: number;
  '20000'?: number;
  '25000'?: number;
  '30000'?: number;
  '35000'?: number;
  '40000'?: number;
  '50000'?: number;
}

export interface MonthKMPrices {
  '12'?: KM;
  '24'?: KM;
  '36'?: KM;
  '48'?: KM;
  '60'?: KM;
}

export interface CantonTax {
  AG: number;
  AI: number;
  AR: number;
  BE: number;
  BL: number;
  BS: number;
  FR: number;
  GE: number;
  GL: number;
  GR: number;
  JU: number;
  LU: number;
  NE: number;
  NW: number;
  OW: number;
  SG: number;
  SH: number;
  SO: number;
  SZ: number;
  TG: number;
  TI: number;
  UR: number;
  VD: number;
  VS: number;
  ZG: number;
  ZH: number;
}

export interface TCOData {
  care: number;
  fuel: KM;
  rent: number;
  tire: KM;
  taxes: CantonTax;
  garage: number;
  service: KM;
  liability: number;
  impairment: KM;
  amortization: number;
  nextoutsourc: number;
  comprehensiveinsurance: number;
}

export interface WarrantyExisting {
  warrantyText: string[];
  warrantyKMLeft: number;
  warrantyMonthsLeft: number;
}

export interface FSLData {
  taxes: CantonTax;
  tyres: MonthKMPrices;
  service: MonthKMPrices;
  vignette: number;
  warranty: MonthKMPrices;
  insurance: MonthKMPrices;
  lastUpdate: Date;
  hasExtraTyres: boolean;
  warrantyExisting: WarrantyExisting | null;
}

export interface VendorPublic {
  id?: number;
  hubspotId: string;
  status: VendorStatus;
  name: string;
  leadEmail?: string;
  slug?: string;
  phone?: string;
  street?: string;
  city?: string;
  zip?: number;
  stateCode?: string;
  countryCode?: string;
  placeId?: string;
  lastGoogleMapsUpdate?: string;
  openingTimesGoogleMaps?: OpeningTimesGoogle;
}

export interface OpeningTimesGoogle {
  friday: Day[];
  monday: Day[];
  sunday: Day[];
  tuesday: Day[];
  saturday: Day[];
  thursday: Day[];
  wednesday: Day[];
}

export interface AutoIDeal {
  LetzterNP: number;
  Marke: string;
  ModellDe: string;
  PS: number;
  ProdVon: number;
  TypDe: string;
}

export interface DealPublic {
  id: number;
  publicId: string;
  status: DealStatus;
  isLockedToSource: boolean;
  isOwnershipDefault: boolean;
  isResidualValueDefault: boolean;
  residualValues?: ResidualValuePublic[];
  leasingProvider: LeasingProvider;
  leasingInterestRate: number;
  businessLeasing?: boolean;
  residualValueCalculation: ResidualValueCalculation;
  fee: number;
  listing: ListingPublic;
  dealerDeliveryFee: number;
  totalCarPrice: number;
  autoIFields: AutoIDeal;
  publishErrors: PublishBackendError;
}

export interface AIOCosts {
  insurance?: Record<string, string | number>;
  taxes?: Record<string, Record<string, number>>;
  warranty?: Record<string, Record<string, number>>;
  service?: Record<string, Record<string, number>>;
  tyres?: Record<string, Record<string, number>>;
  vignette?: number;
}

export interface AIOYearlyFees {
  gowago?: number;
  mss?: number;
}

export interface AIOData {
  costs: AIOCosts;
  yearlyFees: AIOYearlyFees;
}

export interface ListingPublic {
  id: number;
  typenschein?: string;
  vehicleNumber?: string;
  chassisNumber?: string;
  source?: SourceType;
  sourceCreated?: Date;
  sourceUpdated?: Date;
  sourceChecked?: Date;
  externalId?: string;
  externalUrl?: string;
  title?: string;
  make?: Make;
  model?: string;
  modelSlug?: string;
  modelYear?: number;
  modelMonth?: number;
  natCode?: number;
  engine?: string;
  listPrice?: number;
  price?: number;
  fuelType?: FuelType;
  fuelTypeDetailed?: FuelTypesDetailed;
  transmissionDetailed?: TransmissionDetailed;
  drivenWheels?: DrivenWheels;
  seats?: number;
  doors?: number;
  hp?: number;
  cylinder?: number;
  cylinderCapacity?: number;
  curbWeight?: number;
  towingCapacity?: number;
  trunkCap?: number;
  co2Emissions?: number;
  co2EmissionsFromFuelAndElectricity?: number;
  co2EmissionsAverageOfAllSoldNewVehicles?: number;
  energyEfficiency?: ConsumptionRating;
  euroNorm?: PollutionNorm;
  consumptionCity?: number;
  consumptionCountryside?: number;
  consumptionTotal?: number;
  consumptionElectric?: number;
  consumptionGasCity?: number;
  consumptionGasCountryside?: number;
  consumptionGasTotal?: number;
  petrolEquivalent?: number;
  colorOutside?: string;
  colorOutsideHEX?: string;
  colorOutsideCode?: number;
  colorOutsideManuId?: number;
  colorInside?: string;
  segments?: Segments[];
  carUsage?: CarUsage[];
  firstRegYear?: number;
  firstRegMonth?: number;
  licensing?: string;
  licensingDate?: Date;
  conditionTypeDetailed?: ConditionType;
  km?: number;
  greyImport?: boolean;
  mfk?: boolean;
  warranty?: boolean;
  warrantyText?: string[];
  warrantyMonthsLeft?: number;
  warrantyInMonths?: number;
  warrantyKMLeft?: number;
  warrantyInKM?: number;
  warrantyUntilDate?: Date;
  warrantyStartType?: StartTimeOfWarranty;
  handicappedAccessible?: boolean;
  forExport?: boolean;
  racingCar?: boolean;
  tuning?: boolean;
  accidentVehicle?: boolean;
  lastCheck?: Date;
  commentHTML?: string;
  options?: OptionName[];
  optionsExtended?: OptionExtended[];
  optionsDetailed?: OptionsDetailed;
  imageMain?: ImageFormatUrls;
  images?: ImageGalleryElement[];
  carLocation?: CarLocation;
  deliveredIn: DeliveredIn;
  vendor?: VendorPublic;
  aio?: AIOData;
  createdAt?: string;
  updatedAt?: string;
  batteryCapacity?: number;
  batteryRange?: number;
}

export interface ResidualValuePublic {
  month: MonthType;
  km: KMType;
  residualValue: number;
  leasingRateDefault: number;
  leasingRateMax: number;
  leasingRateMin: number;
  residualValueAbsolute: number;
}

export interface BackendErrorMessage {
  children: Array<BackendErrorMessage>;
  constraints: Record<string, string>;
}

export interface PublishBackendError {
  id: number;
  publishErrors: {
    data: Record<string, string | BackendErrorValue>;
  };
}

export interface BackendErrorValue {
  actual: string;
  expected: string;
  reason: Record<string, any>;
}

export enum SalaryPaymentNumber {
  TWELVE = 12,
  THIRTEEN = 13,
}

export enum Citizenship {
  SWISS_CITIZEN = 'SWISS_CITIZEN',
  B_WORK_PERMIT = 'B_WORK_PERMIT',
  C_WORK_PERMIT = 'C_WORK_PERMIT',
  G_OR_L_WORK_PERMIT = 'G_OR_L_WORK_PERMIT',
  NOT_SWISS_CITIZEN = 'NOT_SWISS_CITIZEN',
}

export enum Nationality {
  'AFGHANISTAN' = 'Afghanistan',
  'ÅLAND_ISLANDS' = 'Åland Islands',
  'ALBANIA' = 'Albania',
  'ALGERIA' = 'Algeria',
  'AMERICAN_SAMOA' = 'American Samoa',
  'ANDORRA' = 'Andorra',
  'ANGOLA' = 'Angola',
  'ANGUILLA' = 'Anguilla',
  'ANTARCTICA' = 'Antarctica',
  'ANTIGUA_AND_BARBUDA' = 'Antigua and Barbuda',
  'ARGENTINA' = 'Argentina',
  'ARMENIA' = 'Armenia',
  'ARUBA' = 'Aruba',
  'AUSTRALIA' = 'Australia',
  'AUSTRIA' = 'Austria',
  'AZERBAIJAN' = 'Azerbaijan',
  'BAHAMAS' = 'Bahamas',
  'BAHRAIN' = 'Bahrain',
  'BANGLADESH' = 'Bangladesh',
  'BARBADOS' = 'Barbados',
  'BELARUS' = 'Belarus',
  'BELGIUM' = 'Belgium',
  'BELIZE' = 'Belize',
  'BENIN' = 'Benin',
  'BERMUDA' = 'Bermuda',
  'BHUTAN' = 'Bhutan',
  'BOLIVIA' = 'Bolivia',
  'BOSNIA_AND_HERZEGOVINA' = 'Bosnia and Herzegovina',
  'BOTSWANA' = 'Botswana',
  'BOUVET_ISLAND' = 'Bouvet Island',
  'BRAZIL' = 'Brazil',
  'BRITISH_INDIAN_OCEAN_TERRITORY' = 'British Indian Ocean Territory',
  'BRITISH_VIRGIN_ISLANDS' = 'British Virgin Islands',
  'BRUNEI' = 'Brunei',
  'BULGARIA' = 'Bulgaria',
  'BURKINA_FASO' = 'Burkina Faso',
  'BURUNDI' = 'Burundi',
  'CAMBODIA' = 'Cambodia',
  'CAMEROON' = 'Cameroon',
  'CANADA' = 'Canada',
  'CAPE_VERDE' = 'Cape Verde',
  'CARIBBEAN_NETHERLANDS' = 'Caribbean Netherlands',
  'CAYMAN_ISLANDS' = 'Cayman Islands',
  'CENTRAL_AFRICAN_REPUBLIC' = 'Central African Republic',
  'CHAD' = 'Chad',
  'CHILE' = 'Chile',
  'CHINA' = 'China',
  'CHRISTMAS_ISLAND' = 'Christmas Island',
  'COCOS_KEELING_ISLANDS' = 'Cocos (Keeling) Islands',
  'COLOMBIA' = 'Colombia',
  'COMOROS' = 'Comoros',
  'CONGO' = 'Congo',
  'COOK_ISLANDS' = 'Cook Islands',
  'COSTA_RICA' = 'Costa Rica',
  'COTE_DIVOIRE' = "Cote d'Ivoire",
  'CROATIA' = 'Croatia',
  'CUBA' = 'Cuba',
  'CURAÇAO' = 'Curaçao',
  'CYPRUS' = 'Cyprus',
  'CZECH_REPUBLIC' = 'Czech Republic',
  'DEMOCRATIC_REPUBLIC_OF_THE_CONGO' = 'Democratic Republic of the Congo',
  'DENMARK' = 'Denmark',
  'DJIBOUTI' = 'Djibouti',
  'DOMINICA' = 'Dominica',
  'DOMINICAN_REPUBLIC' = 'Dominican Republic',
  'EAST_TIMOR' = 'East Timor',
  'ECUADOR' = 'Ecuador',
  'EGYPT' = 'Egypt',
  'EL_SALVADOR' = 'El Salvador',
  'EQUATORIAL_GUINEA' = 'Equatorial Guinea',
  'ERITREA' = 'Eritrea',
  'ESTONIA' = 'Estonia',
  'ETHIOPIA' = 'Ethiopia',
  'FALKLAND_ISLANDS' = 'Falkland Islands',
  'FAROE_ISLANDS' = 'Faroe Islands',
  'FIJI' = 'Fiji',
  'FINLAND' = 'Finland',
  'FRANCE' = 'France',
  'FRENCH_GUIANA' = 'French Guiana',
  'FRENCH_POLYNESIA' = 'French Polynesia',
  'FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS' = 'French Southern and Antarctic Lands',
  'GABON' = 'Gabon',
  'GAMBIA' = 'Gambia',
  'GEORGIA' = 'Georgia',
  'GERMANY' = 'Germany',
  'GHANA' = 'Ghana',
  'GIBRALTAR' = 'Gibraltar',
  'GREECE' = 'Greece',
  'GREENLAND' = 'Greenland',
  'GRENADA' = 'Grenada',
  'GUADELOUPE' = 'Guadeloupe',
  'GUAM' = 'Guam',
  'GUATEMALA' = 'Guatemala',
  'GUERNSEY' = 'Guernsey',
  'GUINEA' = 'Guinea',
  'GUINEA_BISSAU' = 'Guinea-Bissau',
  'GUYANA' = 'Guyana',
  'HAITI' = 'Haiti',
  'HEARD_ISLAND_AND_MCDONALD_ISLANDS' = 'Heard Island and McDonald Islands',
  'HONDURAS' = 'Honduras',
  'HONG_KONG' = 'Hong Kong',
  'HUNGARY' = 'Hungary',
  'ICELAND' = 'Iceland',
  'INDIA' = 'India',
  'INDONESIA' = 'Indonesia',
  'IRAN' = 'Iran',
  'IRAQ' = 'Iraq',
  'IRELAND' = 'Ireland',
  'ISLE_OF_MAN' = 'Isle of Man',
  'ISRAEL' = 'Israel',
  'ITALY' = 'Italy',
  'JAMAICA' = 'Jamaica',
  'JAPAN' = 'Japan',
  'JERSEY' = 'Jersey',
  'JORDAN' = 'Jordan',
  'KAZAKHSTAN' = 'Kazakhstan',
  'KENYA' = 'Kenya',
  'KIRIBATI' = 'Kiribati',
  'KUWAIT' = 'Kuwait',
  'KYRGYZSTAN' = 'Kyrgyzstan',
  'LAOS' = 'Laos',
  'LATVIA' = 'Latvia',
  'LEBANON' = 'Lebanon',
  'LESOTHO' = 'Lesotho',
  'LIBERIA' = 'Liberia',
  'LIBYA' = 'Libya',
  'LIECHTENSTEIN' = 'Liechtenstein',
  'LITHUANIA' = 'Lithuania',
  'LUXEMBOURG' = 'Luxembourg',
  'MACAU' = 'Macau',
  'MACEDONIA_FYROM' = 'Macedonia (FYROM)',
  'MADAGASCAR' = 'Madagascar',
  'MALAWI' = 'Malawi',
  'MALAYSIA' = 'Malaysia',
  'MALDIVES' = 'Maldives',
  'MALI' = 'Mali',
  'MALTA' = 'Malta',
  'MARSHALL_ISLANDS' = 'Marshall Islands',
  'MARTINIQUE' = 'Martinique',
  'MAURITANIA' = 'Mauritania',
  'MAURITIUS' = 'Mauritius',
  'MAYOTTE' = 'Mayotte',
  'MEXICO' = 'Mexico',
  'MICRONESIA' = 'Micronesia',
  'MOLDOVA' = 'Moldova',
  'MONACO' = 'Monaco',
  'MONGOLIA' = 'Mongolia',
  'MONTENEGRO' = 'Montenegro',
  'MONTSERRAT' = 'Montserrat',
  'MOROCCO' = 'Morocco',
  'MOZAMBIQUE' = 'Mozambique',
  'MYANMAR_BURMA' = 'Myanmar (Burma)',
  'NAMIBIA' = 'Namibia',
  'NAURU' = 'Nauru',
  'NEPAL' = 'Nepal',
  'NETHERLANDS' = 'Netherlands',
  'NETHERLANDS_ANTILLES' = 'Netherlands Antilles',
  'NEW_CALEDONIA' = 'New Caledonia',
  'NEW_ZEALAND' = 'New Zealand',
  'NICARAGUA' = 'Nicaragua',
  'NIGER' = 'Niger',
  'NIGERIA' = 'Nigeria',
  'NIUE' = 'Niue',
  'NORFOLK_ISLAND' = 'Norfolk Island',
  'NORTH_KOREA' = 'North Korea',
  'NORTHERN_MARIANA_ISLANDS' = 'Northern Mariana Islands',
  'NORWAY' = 'Norway',
  'OMAN' = 'Oman',
  'PAKISTAN' = 'Pakistan',
  'PALAU' = 'Palau',
  'PALESTINE' = 'Palestine',
  'PANAMA' = 'Panama',
  'PAPUA_NEW_GUINEA' = 'Papua New Guinea',
  'PARAGUAY' = 'Paraguay',
  'PERU' = 'Peru',
  'PHILIPPINES' = 'Philippines',
  'PITCAIRN_ISLANDS' = 'Pitcairn Islands',
  'POLAND' = 'Poland',
  'PORTUGAL' = 'Portugal',
  'PUERTO_RICO' = 'Puerto Rico',
  'QATAR' = 'Qatar',
  'RÉUNION' = 'Réunion',
  'ROMANIA' = 'Romania',
  'RUSSIA' = 'Russia',
  'RWANDA' = 'Rwanda',
  'SAINT_BARTHÉLEMY' = 'Saint Barthélemy',
  'SAINT_HELENA' = 'Saint Helena',
  'SAINT_KITTS_AND_NEVIS' = 'Saint Kitts and Nevis',
  'SAINT_LUCIA' = 'Saint Lucia',
  'SAINT_MARTIN' = 'Saint Martin',
  'SAINT_PIERRE_AND_MIQUELON' = 'Saint Pierre and Miquelon',
  'SAINT_VINCENT_AND_THE_GRENADINES' = 'Saint Vincent and the Grenadines',
  'SAMOA' = 'Samoa',
  'SAN_MARINO' = 'San Marino',
  'SAO_TOME_AND_PRINCIPE' = 'Sao Tome and Principe',
  'SAUDI_ARABIA' = 'Saudi Arabia',
  'SENEGAL' = 'Senegal',
  'SERBIA' = 'Serbia',
  'SEYCHELLES' = 'Seychelles',
  'SIERRA_LEONE' = 'Sierra Leone',
  'SINGAPORE' = 'Singapore',
  'SINT_MAARTEN' = 'Sint Maarten',
  'SLOVAKIA' = 'Slovakia',
  'SLOVENIA' = 'Slovenia',
  'SOLOMON_ISLANDS' = 'Solomon Islands',
  'SOMALIA' = 'Somalia',
  'SOUTH_AFRICA' = 'South Africa',
  'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS' = 'South Georgia and the South Sandwich Islands',
  'SOUTH_KOREA' = 'South Korea',
  'SOUTH_SUDAN' = 'South Sudan',
  'SPAIN' = 'Spain',
  'SRI_LANKA' = 'Sri Lanka',
  'SUDAN' = 'Sudan',
  'SURINAME' = 'Suriname',
  'SVALBARD_AND_JAN_MAYEN' = 'Svalbard and Jan Mayen',
  'SWAZILAND' = 'Swaziland',
  'SWEDEN' = 'Sweden',
  'SWITZERLAND' = 'Switzerland',
  'SYRIA' = 'Syria',
  'TAIWAN' = 'Taiwan',
  'TAJIKISTAN' = 'Tajikistan',
  'TANZANIA' = 'Tanzania',
  'THAILAND' = 'Thailand',
  'TOGO' = 'Togo',
  'TOKELAU' = 'Tokelau',
  'TONGA' = 'Tonga',
  'TRINIDAD_AND_TOBAGO' = 'Trinidad and Tobago',
  'TUNISIA' = 'Tunisia',
  'TURKEY' = 'Turkey',
  'TURKMENISTAN' = 'Turkmenistan',
  'TURKS_AND_CAICOS_ISLANDS' = 'Turks and Caicos Islands',
  'TUVALU' = 'Tuvalu',
  'U_S_VIRGIN_ISLANDS' = 'U.S. Virgin Islands',
  'UGANDA' = 'Uganda',
  'UKRAINE' = 'Ukraine',
  'UNITED_ARAB_EMIRATES' = 'United Arab Emirates',
  'UNITED_KINGDOM' = 'United Kingdom',
  'UNITED_STATES' = 'United States',
  'UNITED_STATES_MINOR_OUTLYING_ISLANDS' = 'United States Minor Outlying Islands',
  'URUGUAY' = 'Uruguay',
  'UZBEKISTAN' = 'Uzbekistan',
  'VANUATU' = 'Vanuatu',
  'VATICAN_CITY' = 'Vatican City',
  'VENEZUELA' = 'Venezuela',
  'VIETNAM' = 'Vietnam',
  'WALLIS_AND_FUTUNA' = 'Wallis and Futuna',
  'WESTERN_SAHARA' = 'Western Sahara',
  'YEMEN' = 'Yemen',
  'ZAMBIA' = 'Zambia',
  'ZIMBABWE' = 'Zimbabwe',
}

export enum ChildrenCare {
  SINGLE_PARENT = 'SINGLE_PARENT',
  TOGETHER_WITH_PARTNER = 'TOGETHER_WITH_PARTNER',
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  IN_REGISTERED_PARTNERSHIP = 'IN_REGISTERED_PARTNERSHIP',
  PARTNERSHIP_DISSOLVED_BY_COURT = 'PARTNERSHIP_DISSOLVED_BY_COURT',
}

export enum EmploymentType {
  EMPLOYED_ON_MONTHLY_BASIS = 'EMPLOYED_ON_MONTHLY_BASIS',
  EMPLOYED_ON_HOURLY_BASIS = 'EMPLOYED_ON_HOURLY_BASIS',
  EMPLOYED_BASED_WITH_COMMISSIONS = 'EMPLOYED_BASED_WITH_COMMISSIONS',
}

export enum TypeOfLoan {
  MIGROS_BANK_PERSONAL_LOAN = 'MIGROS_BANK_PERSONAL_LOAN',
  CASH_OR_PERSONAL_LOAN = 'CASH_OR_PERSONAL_LOAN',
  INSTALLMENT_LOAN = 'INSTALLMENT_LOAN',
  LEASING = 'LEASING',
  CREDIT_CARD_DEBT = 'CREDIT_CARD_DEBT',
  ACCOUNT_OVERDRAFT_LIMIT = 'ACCOUNT_OVERDRAFT_LIMIT',
  LOANS_ABROAD = 'LOANS_ABROAD',
  OTHER_LOANS = 'OTHER_LOANS',
}

export interface CustomerKids {
  haveKids: boolean;
  fromZeroToFive?: number;
  fromSixToNine?: number;
  fromTenToEleven?: number;
  olderThanEleven?: number;
  childrenCare?: ChildrenCare;
}

interface CustomerBaseAddress {
  street: string;
  city: string;
  zip: string;
}

export type CustomerAddress = CustomerBaseAddress & {
  livedSince: Date;
};

export type CustomerCorrespondenceAddress = Partial<CustomerBaseAddress> & {
  differentCorrespondenceAddress: boolean;
};

export interface CustomerEmployment {
  companyName: string;
  companyCity: string;
  companyZip: string;
  type: EmploymentType;
  phoneNumber: string;
  since: string;
}

export interface CustomerYearlyIncome {
  hasThirteenthSalary?: boolean;
  withholdingTaxFromIncome?: boolean;
}

export interface CustomerMonthlyIncome {
  netIncome: number;
  hasAdditionalIncome?: boolean;
  additionalIncome?: number;
}

export enum HousingSituation {
  LIVING_ALONE = 'LIVING_ALONE',
  LIVING_TOGETHER = 'LIVING_TOGETHER',
  MARRIED_COUPLE_IN_PERMANENT_HOUSEHOLD_COMMUNITY = 'MARRIED_COUPLE_IN_PERMANENT_HOUSEHOLD_COMMUNITY',
}

export interface CustomerMonthlyCosts {
  housing: number;
  healthInsurance: number;
  additionalHealthCare: number;
  professionalExpenses: number;
  otherExpenses: number;
  childrenEducation?: number;
  paysAlimony?: boolean;
  alimony?: number;
  paysSupportForOthers?: boolean;
  numberOfSupportedPeople?: number;
}

export interface CustomerRunningCredit {
  typeOfLoan: TypeOfLoan;
  endDate: Date;
  monthlyRate: number;
  bankOrCompany: string;
}

export interface CustomerUnemploymentPeriod {
  reason: string;
  isNotWorkingCurrently: boolean;
  year?: number;
  months: number;
}

export enum CustomerAuthAction {
  RESET_PHONE = 'RESET_PHONE',
  OTP_VERIFICATION = 'OTP_VERIFICATION',
  REGISTRATION = 'REGISTRATION',
}

export interface ResetPasswordData {
  customerId: number;
  phoneNumber: string;
}

export enum CustomerLoginRequestType {
  GET_OTP = 'GET_OTP',
  OTP_VERIFY = 'OTP_VERIFY',
}

export interface DefaultVendorParams {
  vendorId?: string;
}

export enum UploadUpdateFileTypeAdmin {
  KFP_RESULTS = 'KFPResults',
}

export enum CustomerFileTypeAdmin {
  WORKING_CONTRACT = 'WorkingContract',
  INSURANCE_POLICY = 'InsurancePolicy',
  EMPLOYER_LETTERS = 'EmployerLetters',
  BANKING_STATEMENT = 'BankingStatement',
  PURCHASE_AGREEMENT = 'PurchaseAgreement',
  PURCHASE_AGREEMENT_CUSTOMER_GOWAGO = 'PurchaseAgreementCustomerGowago',
  PURCHASE_AGREEMENT_RESELLER_GOWAGO = 'PurchaseAgreementResellerGowago',
  HANDOVER_PROTOCOL = 'HandoverProtocol',
  PASSPORT = 'Passport',
  OTHER_1 = 'Other1',
  OTHER_2 = 'Other2',
  OTHER_3 = 'Other3',
  TRADEIN_OTHER = 'TradeIn_Other',
  DEBT_ENFORCEMENT_EXTRACT = 'DebtEnforcementExtract',
  DEBT_ENFORCEMENT_EXTRACT_2 = 'DebtEnforcementExtract2',
  DEBT_ENFORCEMENT_EXTRACT_3 = 'DebtEnforcementExtract3',
  FLEET_DISCOUNT_CONFIRMATION = 'FleetDiscountConfirmation',
  CERTIFIED_COPY_ID = 'CertifiedCopyID',
  CERTIFIED_COPY_PASSPORT = 'CertifiedCopyPassport',
  CERTIFIED_COPY_RESIDENCE_PERMIT = 'CertifiedCopyResidencePermit',
  RESIDENCE_PERMIT_RENEWAL_CONFIRMATION = 'ResidencePermitRenewalConfirmation',
  OTHER_ID_DOCS = 'OtherIDDocs',
  TRAIL_PERIOD_CONFIRMATION = 'TrialPeriodConfirmation',
  TEST_EMAIL_COMPANY_DOMAIN = 'TestEmailCompanyDomain',
  EMPLOYMENT_CONFIRMATION = 'EmploymentConfirmation',
  OTHER_EMPLOYMENT_DOCS = 'OtherEmploymentDocs',
  BANKING_STATEMENT_1 = 'BankingStatement1',
  BANKING_STATEMENT_2 = 'BankingStatement2',
  BANKING_STATEMENT_3 = 'BankingStatement3',
  BANKING_STATEMENT_4 = 'BankingStatement4',
  BANKING_STATEMENT_5 = 'BankingStatement5',
  BANKING_STATEMENT_6 = 'BankingStatement6',
  FITNESS_TO_WORK_CERTIFICATE = 'FitnessToWorkCertificate',
  RENTAL_AGREEMENT = 'RentalAgreement',
  TAX_DECLARATION = 'TaxDeclaration',
  OTHER_PROOF_OF_FINANCIAL_VIABILITY = 'OtherProofOfFinancialViability',
  THIRD_PARTY_CREDIT_AGREEMENT = 'ThirdPartyCreditAgreement',
  THIRD_PARTY_LEASING_AGREEMENT = 'ThirdPartyLeasingAgreement',
  CRIF_REPORT = 'CRIFReport',
}

export enum OfferFileTypeAdmin {
  PRIVATE_CREDIT_CONTRACT = 'PrivateCreditContract',
  PRIVATE_CREDIT_PRE_APPROVAL = 'PreApprovalPrivateCredit',
  LEASING_CONTRACT = 'LeasingContract',
  LEASING_CONTRACT_WITHOUT_KFP = 'LeasingContractWithoutKFP',
  EQUIPMENT = 'Equipment',
  CARTE_GRIS = 'CarteGris',
  PCAS_OPT_CONFORMATION = 'PCASOptConfirmation',
  KFP = 'KFP',
  AIO_CONTRACT_SIGNED = 'AIOContractSigned',
  AIO_CONTRACT = 'AIOContract',
  BUYING_CONTRACT_DEALER = 'BuyingContractDealer',
  RESIDUAL_VALUE_OBLIGATION_MIGROS = 'ResidualValueObligationMigros',
  INVOICE_DEALER = 'InvoiceDealer',
  HANDOVER_PROTOCOL_AIO = 'HandoverProtocol_AIO',
  HANDOVER_PROTOCOL_MB = 'HandoverProtocol_MB',
  MSS_OFFER = 'MSSOffer',
  MSS_OFFER_1 = 'MSSOffer1',
  MSS_OFFER_2 = 'MSSOffer2',
  MSS_OFFER_3 = 'MSSOffer3',
  AIO_CALCULATION = 'AIOCalculation',
  TAX_YEAR_1 = 'TaxYear1',
  TAX_YEAR_2 = 'TaxYear2',
  TAX_YEAR_3 = 'TaxYear3',
  TAX_YEAR_4 = 'TaxYear4',
  TAX_YEAR_5 = 'TaxYear5',
  CAR_REGISTRATION_INVOICE = 'CarRegistrationInvoice',
  OTHER = 'Other',
  ANZEIGE_ZESSION = 'AnzeigeZession',
  INVOICE_MIBA = 'InvoiceMiba',
  PURCHASE_AGREEMENT_MIBA = 'PurchaseAgreementMiba',
  FORM_A = 'FormA',
  FORM_K = 'FormK',
  FORM_KYC = 'FormKYC',
  POWER_OF_ATTORNEY = 'PowerOfAttorney',
  BUSINESS_LEASING_ZESSION = 'Business_Leasing_Zession',
  PRIVATE_CREDIT_PURCHASE_AGREEMENT = 'PrivateCreditPurchaseAgreement',
  DOWN_PAYMENT_INVOICE = 'DownPaymentInvoice',
}

export enum OfferFileType {
  LOG_TC_AND_ZEK = 'LogTCAndZEK',
}

export enum CustomerFileType {
  ID_DOCUMENT_FRONT = 'IDFront',
  ID_DOCUMENT_BACK = 'IDBack',
  RESIDENCE_PERMIT_FRONT = 'ResidencePermitFront',
  RESIDENCE_PERMIT_BACK = 'ResidencePermitBack',
  RESIDENCE_PERMIT_BOTH_SIDES = 'ResidencePermitBothSides',
  SALARY_SLIPS_COLLECTION = 'SalarySlipsCollection',
  ADDRESS_CHANGE_FORM = 'AddressChangeForm',
  PAY_SLIP_FIRST = 'SalarySlip1',
  PAY_SLIP_SECOND = 'SalarySlip2',
  PAY_SLIP_THIRD = 'SalarySlip3',
  PAY_SLIP_FOURTH = 'SalarySlip4',
  PAY_SLIP_FIFTH = 'SalarySlip5',
  PAY_SLIP_SIXTH = 'SalarySlip6',
  ADDITIONAL_INCOME_SLIP_1 = 'AdditionalIncomeSlip1',
  ADDITIONAL_INCOME_SLIP_2 = 'AdditionalIncomeSlip2',
  ADDITIONAL_INCOME_SLIP_3 = 'AdditionalIncomeSlip3',
  ADDITIONAL_INCOME_SLIP_4 = 'AdditionalIncomeSlip4',
  ADDITIONAL_INCOME_SLIP_5 = 'AdditionalIncomeSlip5',
  ADDITIONAL_INCOME_SLIP_6 = 'AdditionalIncomeSlip6',
  TRADE_IN_REGISTRATION = 'Registration',
  TRADE_IN_DAMAGE1 = 'DamageImage1',
  TRADE_IN_DAMAGE2 = 'DamageImage2',
  TRADE_IN_DAMAGE3 = 'DamageImage3',
  TRADE_IN_DAMAGE4 = 'DamageImage4',
  TRADE_IN_DAMAGE5 = 'DamageImage5',
  TRADE_IN_DAMAGE6 = 'DamageImage6',
  TRADE_IN_DAMAGE7 = 'DamageImage7',
  TRADE_IN_DAMAGE8 = 'DamageImage8',
  TRADE_IN_FRONT = 'Front',
  TRADE_IN_REAR = 'Rear',
  TRADE_IN_DRIVER_SIDE = 'DriverSide',
  TRADE_IN_PASSENGER_SIDE = 'PassengerSide',
  TRADE_IN_TYRES = 'Tyres',
  TRADE_IN_FRONT_SEATS = 'FrontSeats',
  TRADE_IN_DASHBOARD = 'Dashboard',
}

export enum PartnerSpouseDocumentFileTypeAdmin {
  PARTNER_ID = 'PartnerID',
  PARTNER_PASSPORT = 'PartnerPassport',
  PARTNER_RESIDENCE_PERMIT = 'PartnerResidencePermit',
}

export type FileTypeUploadedByCustomer = CustomerFileType | OfferFileType;

export type FileTypeUploadedByAdmin = CustomerFileTypeAdmin | OfferFileTypeAdmin | PartnerSpouseDocumentFileTypeAdmin;

export type FileType = FileTypeUploadedByCustomer | FileTypeUploadedByAdmin | UploadUpdateFileTypeAdmin;

export type AllFileTypes = CustomerFileType &
  OfferFileType &
  CustomerFileTypeAdmin &
  OfferFileTypeAdmin &
  UploadUpdateFileTypeAdmin;

export type UploadedDocuments = {
  [K in FileType]?: string;
};

export interface GetCustomerFilesRequest {
  customerId: number;
  offerId: string;
  fileTypes: string[];
}

export interface UploadCustomerFileRequest {
  customerId: number;
  offerId: string;
  formData: FormData;
  fileType: FileType;
  isAdminFileType: boolean;
  uploadUpdateFileTypeSlug?: string;
  isMostUsedDocument?: boolean;
}

export interface DeleteCustomerFileRequest {
  customerId: number;
  offerId: string;
  fileType: FileType;
}

export interface HubspotImageObject {
  access: string;
  archived: boolean;
  createdAt: Date;
  defaultHostingUrl: string;
  encoding: string;
  extension: string;
  height: number;
  id: string;
  isUsableInContent: boolean;
  name: string;
  parentFolderId: string;
  path: string;
  size: number;
  type: string;
  updatedAt: Date;
  url: string;
  width: number;
}

export interface CheckoutAddress {
  city: string;
  street?: string;
  streetName: string;
  streetNumber: string;
  zip: number;
  livedSince: string;
  canton?: string;
}

export interface OrderQueryParams {
  orderId: string;
}

export interface DealResultsRequestArgs {
  vendorId: number;
  filters?: Partial<DealResultsFilters>;
}

export interface PartnerOfferRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dealId: string;
  downPayment: number;
  downPaymentType: 'a';
  leasingMileage: LeasingMileage;
  leasingPeriod: LeasingPeriod;
  canton: string;
  language: Language;
  ownershipType: OwnershipType;
  initialCarPrice: number;
  deliveryFee: number;
  accessoriesPrice: number;
  aioPackageName: AIOPackageName;
}

export enum OfferStatus {
  ORDER_STARTED = 'order_started',
  DOC_AND_ZEK_CHECK = 'doc_and_zek_check',
  CREDIT_CHECK = 'credit_check',
  LEASING_APPLICATION = 'leasing_application',
  EXTRA_DOCUMENTS = 'extra_documents',
  FINAL_LEASING_APPROVAL = 'final_leasing_approval',
  CONTRACT_SIGNATURE = 'contract_signature',
  PURCHASING = 'purchasing',
  CLOSED_LOST = 'closed_lost',
}

export type PublicOffer = {
  offerId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth?: Date;
  citizenship?: Citizenship;
  nationality?: Nationality;
  residentSince?: Date;
  maritalStatus?: MaritalStatus;
  language: Language;
  contractLanguage?: ContractLanguage;
  kids?: CustomerKids;
  previousAddresses?: CustomerAddress[];
  correspondenceAddress?: CustomerCorrespondenceAddress;
  employment?: CustomerEmployment;
  yearlyIncome?: CustomerYearlyIncome;
  monthlyIncome?: CustomerMonthlyIncome;
  monthlyCosts?: CustomerMonthlyCosts;
  runningCredits?: CustomerRunningCredit[];
  unemploymentPeriods?: CustomerUnemploymentPeriod[];
  authorizeMigrosBank?: boolean;
  authorizationDate?: Date;
  isLocked?: boolean;
  housingSituation?: HousingSituation;
  hasTradeInType?: HasTradeInType;
  dealId: string;
  downPayment: number;
  leasingPeriod: MonthType;
  leasingMileage: KMType;
  canton: string;
  ageCategory: AgeCategory;
  aioPackageName: AIOPackageName;
  insuranceAgeCategory: AgeCategory;
  insuranceNationality: Nationality;
  carPrice: number;
  initialCarPrice?: number;
  listPrice?: number;
  dealerDeliveryFee?: number;
  dealerAccessoriesPrice?: number;
  residualValueConfirmed: number;
  nominalInterestRateOriginal: number;
  interestRateNominalConfirmed: number;
  interestRateDiscount?: number;
  leasingRateConfirmed?: number;
  aioMonthlyRateConfirmed?: number;
  totalMonthlyPrice: number;

  // Private credit fields
  privateCreditFinancingAmount?: number;
  privateCreditDuration?: MonthType;
  privateCreditDownPayment?: number;
  privateCreditMonthlyRate?: number;
  privateCreditInterestRateNominal?: number;
  privateCreditInterestRateEffective?: number;
  oemReservationFee?: number;
  privateCreditTotalInterestCost?: number;

  partnerFirstName?: string;
  partnerLastName?: string;
  partnerDateOfBirth?: Date;
  underGuardianshipTutorshipOrLegalCounsel?: boolean;
  outstandingDebtCollectionPaymentOrdersNotCancelled?: boolean;
  outstandingDebtCollectionPaymentOrdersInThePast?: boolean;
  isCurrentlyUnemployed?: boolean;
  debtCollectionPaymentOrdersYearReasonAmount?: CustomerDebtCollection[];
  isCustomerPensionBeneficiary?: boolean;
  typeOfPension?: CustomerTypeOfPension;
  isMigrosBankEmployee?: boolean;
  hasAdditionalIncome?: boolean;
  additionalIncomeEmployerName?: string;
  additionalIncomeEmploymentType?: EmploymentType;
  additionalIncomeEmployerStreetName?: string;
  additionalIncomeEmployerStreetNumber?: string;
  additionalIncomeEmployerZip?: number;
  additionalIncomeEmployerCity?: string;
  additionalIncomeEmployerPhone?: string;
  additionalIncomeEmployerDirectPhone?: string;
  additionalIncomeEmployedSince?: Date;

  isCustomerMigrosBankCustomer?: boolean;
  customerMigrosBankAccountNumber?: string;
  customerMigrosBankBranch?: string;
  nearestMigrosBankBranch?: string;
  useMyMigrosBankAccountToPayCredit?: boolean;
  customerMigrosBankPrivateAccountNumber?: string;

  promoCode?: string;
  publicId: string;
  checkoutSteps: CheckoutStepObject[];
  currentStep: CheckoutStep;
  currentSubStep: CheckoutSubStepsType;
  intrumIdentificationLink?: string;
  pandadocCustomerLink?: string;
  pandadocAboContractLink?: string;
  ownershipType: OwnershipType;
  offerType: OfferType;
  hubspotDealId?: string;
  createdAt: string;

  extraKmChf?: number;
  originalMonthlyPrice?: number;
  datetimeDeactivated?: Date;
  b2bCustomerActionSMSDatetimeSent?: Date;
  termsOfServiceAcceptedDatetime?: Date;
  vendorId: number;
  effectiveInterestRate?: number;
  firstLeasingPayment?: number;
  firstTotalPayment?: number;
  addresses?: CustomerAddress[];
  customerId: number;
  customerHubspotId: string;
  promotion?: {
    promoCode: string;
    description: string;
    descriptionDe: string;
    descriptionFr: string;
    yearsOfInsuranceForFree: number;
    chargingCardDiscountInChf: number;
    originalPrice: number;
  };
  offerStatus: OfferStatus;
  advisor?: Advisor;
  esDeal?: Partial<EsDeal>;

  loggedInUserType?: LoggedInUserType;
  whereDidYouHearAboutGowago?: WhereDidYouHearAboutGowago[];
  whereDidYouHearAboutGowagoOther?: string;
  customerApprovals?: PublicCustomerApproval[];
};

export enum CustomerTypeOfPension {
  DISABILITY_INSURANCE = 'disability_insurance',
  WIDOW_WIDOWER_PENSION = 'widow_widower_pension',
}

export interface PublicCustomerApproval {
  validUntilDate: Date;
  customerId: number;
  vendor: { id: number; name: string; hubspotId: string };
}

export enum LoggedInUserType {
  CUSTOMER = 'customer',
  SELLER = 'seller',
}

export interface CustomerDebtCollection {
  year: number;
  reason: string;
  amount: number;
}

export type PublicOfferPricing = PublicOffer & {
  skipContractSignedCheck?: boolean;
  skipResidualValueAndAioPricingCalculation?: boolean;
};

export enum WhereDidYouHearAboutGowago {
  FRIEND_OR_COLLEAGUE = 'friend_colleague',
  FACEBOOK_AD = 'facebook_ad',
  SEARCH_ENGINE = 'search_engine',
  BLOG = 'blog',
  EMPLOYER = 'employer',
  TV_AD = 'tv_ad',
  OTHER = 'other',
}

export enum CustomerApprovalStatus {
  REQUIRED = 'required',
  REQUESTED = 'requested',
  APPROVED = 'approved',
  DENIED = 'denied',
  EXPIRED = 'expired',
}

export interface OfferBasicDealData {
  offerId: string;
  dealId: string;
  downPayment: number;
  leasingPeriod: LeasingPeriod;
  leasingMileage: LeasingMileage;
  aboPeriod: AboPeriod;
  aboMileage: AboMileage;
  ownershipType: OwnershipType;
  offerType: OfferType;
  aioPackageName: AIOPackageName;
  canton: string;
  modelSlug: string;
  model: string;
  makeSlug: string;
  make: string;
  esDeal: EsDeal;
}

export enum InclusionType {
  CAR_LEASE = 'CAR_LEASE',
  CAR = 'CAR',
  ALL_IN_ONE = 'ALL_IN_ONE',
  INSURANCE = 'INSURANCE',
  SERVICING = 'SERVICING',
  EXTENDED_WARRANTY = 'WhatsIncludedExtendedWarranty',
  TYRES_AND_CHANGES = 'TYRES_AND_CHANGES',
  VEHICLE_TAX_AND_VIGNETTE = 'VEHICLE_TAX_AND_VIGNETTE',
  RESIDUAL_VALUE = 'RESIDUAL_VALUE',
  NOMINAL_INTEREST = 'NOMINAL_INTEREST',
  EFFECTIVE_INTEREST = 'EFFECTIVE_INTEREST',
}

export type DownPaymentType = 'r' | 'a';

export interface LeasingConfiguration extends Omit<LeasingConfigurationAPIParams, 'isBusinessCustomer'> {
  downPaymentInCurrency?: number | string;
  downPaymentInPercent?: number;
  canton?: string;
}

export interface LeasingConfigurationAPIParams {
  leasingPeriod: LeasingPeriod;
  leasingMileage: LeasingMileage;
  downPayment: number;
  downPaymentType: DownPaymentType;
  aboPeriod: AboPeriod;
  aboMileage: AboMileage;
  ownershipType: OwnershipType;
  aioPackageName: AIOPackageName;
}

export interface UseParamsParams {
  dealId: string;
  vendorId: string;
}

export enum OEMName {
  TESLA = 'tesla',
}

export enum OfferType {
  B2B = 'B2B',
  B2C = 'B2C',
  OEM = 'OEM',
}

export enum AgeCategory {
  UNDER_25 = '1',
  ABOVE_25 = '2',
}
