import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  SuperAdminActionsFormOverrideContractCheck: {
    id: 'SuperAdminActionsFormOverrideContractCheck',
    defaultMessage: 'Override contract check',
  },
  SuperAdminActionsFormSkipResidualValueAndAioPricingCalculation: {
    id: 'SuperAdminActionsFormSkipResidualValueAndAioPricingCalculation',
    defaultMessage: 'Skip residual value and aio pricing calculation',
  },
});
