import { Locale } from 'types';

export const KEYS = {
  OAUTH_KEY: 'OAUTH_KEY',
  AUTH_TOKEN: 'sales.gowago.auth-token',
  LOCALE: 'sales.gowago.locale',
};

export const removeLocalStorageItem = (itemName: string) => {
  if (!localStorage) {
    return;
  }
  localStorage.removeItem(itemName);
};

export const setLocalStorageItem = (itemName: string, value: string) => {
  if (!localStorage) {
    return;
  }
  localStorage.setItem(itemName, value);
};

export const getLocalStorageItem = (itemName: string) => {
  if (!localStorage) {
    return;
  }
  return localStorage.getItem(itemName);
};

export const setSessionStorageItem = (itemName: string, value: string) => {
  if (!sessionStorage) {
    return;
  }
  sessionStorage.setItem(itemName, value);
};

export const getSessionStorageItem = (itemName: string) => {
  if (!sessionStorage) {
    return;
  }
  return sessionStorage.getItem(itemName);
};

export const removeSessionStorageItem = (itemName: string) => {
  if (!sessionStorage) return;

  sessionStorage.removeItem(itemName);
};

export const getLocale = (): Locale => (getLocalStorageItem(KEYS.LOCALE) as Locale) || 'de-ch';
export const setLocale = (value: Locale) => {
  setLocalStorageItem(KEYS.LOCALE, value);
  window.dispatchEvent(new CustomEvent(KEYS.LOCALE, { detail: value }));
};
