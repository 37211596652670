import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import { faSkullCrossbones } from '@fortawesome/pro-regular-svg-icons/faSkullCrossbones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import ButtonSpinner from 'components/Forms/ButtonSpinner';
import { routeConstants } from 'constants/routes';
import GlobalSelectors from 'pages/_store/global/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { OwnershipType, PublicOffer, PublicOfferPricing } from '../../../types';
import { OrderValidationShema } from '../EditOrderPage.shema';
import Notifications from '../Notifications';
import OfferFileUploadForm from '../OfferFileUploadForm/OfferFileUploadForm';
import { useDeactivateOrder } from '../services/useDeactivateOrder';
import { useReactivateOrder } from '../services/useReactivateOrder';
import { Actions as OrderActions } from '../store/actions';
import { OrderPageState } from '../store/initial-state';
import styles from './FormContent.module.scss';
import { useStyles } from './FormContent.styles';
import OfferCarForm from './OfferCarForm';
import OfferLeasingParamsForm from './OfferLeasingParamsForm';
import OfferOverviewForm from './OfferOverviewForm';
import OfferPrivateCreditForm from './OfferPrivateCreditForm';
import SuperAdminActionsForm from './SuperAdminActionsForm';

interface Params {
  orderId: string;
}

interface Props {
  isDocumentsUpload: boolean;
}

const FormContent: FC<Props> = ({ isDocumentsUpload }) => {
  const intl = useIntl();
  const { orderId } = useParams<Params>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deactivate = useDeactivateOrder();
  const reactivate = useReactivateOrder();

  const {
    orderPage: { order, isUpdatingOrder },
    isSuperAdmin,
    isOfferPricingUpdateAdmin,
  } = useSelector<RootState, { orderPage: OrderPageState; isSuperAdmin: boolean; isOfferPricingUpdateAdmin: boolean }>(
    (state: RootState) => ({
      orderPage: state.orderPage,
      isSuperAdmin: GlobalSelectors.getIsSuperAdmin(state),
      isOfferPricingUpdateAdmin: GlobalSelectors.getIsOfferPricingUpdateAdmin(state),
    }),
    shallowEqual
  );
  const isPrivateCredit = order?.ownershipType === OwnershipType.PRIVATE_CREDIT;

  const resolveInitialValues = () => {
    if (!order) return {};

    return order;
  };

  const methods = useForm<PublicOfferPricing>({
    resolver: yupResolver(OrderValidationShema(intl)),
    defaultValues: resolveInitialValues(),
    shouldUnregister: false,
  });

  // update initial values when deal arrives
  useEffect(() => {
    methods.reset(resolveInitialValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const onSubmit: SubmitHandler<PublicOfferPricing> = (data) => {
    dispatch(
      OrderActions.updateOrderRequest(orderId, {
        ...order,
        ...data,
      } as PublicOffer)
    );

    uncheckSuperAdminCheckboxes();
    uncheckSkipResidualValueAndAioPricingCalculation();
  };

  useEffect(() => {
    methods.reset();
  }, [orderId, methods.reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeactivate = async () => {
    await deactivate(orderId);
    methods.reset();
    dispatch(OrderActions.getOrderRequest(orderId));
  };

  const uncheckSuperAdminCheckboxes = () => methods.setValue('skipContractSignedCheck', false);
  const uncheckSkipResidualValueAndAioPricingCalculation = () =>
    methods.setValue('skipResidualValueAndAioPricingCalculation', false);

  const handleReactivate = async () => {
    await reactivate(orderId);
    methods.reset();
    dispatch(OrderActions.getOrderRequest(orderId));
  };

  const isInactive = !!order?.datetimeDeactivated;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Notifications />
        <div className={styles.mainContainer}>
          <div className={styles.formContainer}>
            <OfferOverviewForm />
            {isDocumentsUpload ? (
              <OfferFileUploadForm />
            ) : (
              <>
                <OfferCarForm />
                <OfferLeasingParamsForm />
                {isPrivateCredit && <OfferPrivateCreditForm />}
                {(isSuperAdmin || isOfferPricingUpdateAdmin) && <SuperAdminActionsForm />}
                <div className={classes.actions}>
                  <Button
                    disabled={isUpdatingOrder || isInactive}
                    className={clsx(classes.button, classes.deactivateBtn)}
                    type="button"
                    onClick={handleDeactivate}
                    startIcon={<FontAwesomeIcon icon={faSkullCrossbones} />}
                  >
                    <FormattedMessage defaultMessage="Deactivate" id="FormContentDeactivate" />
                  </Button>
                  <Button
                    disabled={isUpdatingOrder || !isInactive}
                    className={clsx(classes.button, classes.reactivateBtn)}
                    type="button"
                    onClick={handleReactivate}
                    startIcon={<FontAwesomeIcon icon={faUndo} />}
                  >
                    <FormattedMessage defaultMessage="Reactivate" id="FormContentReactivate" />
                  </Button>
                  <NavLink
                    to={routeConstants.PROTECTED.VIEW_ORDER_FILES_PAGE.url(orderId)}
                    exact
                    className={classes.navLink}
                  >
                    <Button disabled={isUpdatingOrder} className={classes.button} type="button">
                      <FormattedMessage defaultMessage="Documents" id="FormContentDocuments" />
                    </Button>
                  </NavLink>
                  <Button disabled={isUpdatingOrder || isInactive} className={classes.submitBtn} type="submit">
                    <ButtonSpinner loading={isUpdatingOrder}>
                      <FormattedMessage defaultMessage="Save" id="FormContentSave" />
                    </ButtonSpinner>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormContent;
