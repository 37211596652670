import { createRequestQueryArray } from 'utils/api';
import {
  DeleteCustomerFileRequest,
  GetCustomerFilesRequest,
  OEMName,
  OfferType,
  OrdersQuery,
  OrdersQueryResult,
  PublicOffer,
  UpdateOrderQuery,
  UploadCustomerFileRequest,
  VendorOrdersQuery,
} from '../types';
import ApiClient from './apiClient';

export const getOffers = async ({ query, $skip, $limit }: OrdersQuery): Promise<OrdersQueryResult> => {
  return ApiClient.post('offer/search', {
    query,
    $skip: Number($skip),
    $limit: Number($limit),
  });
};

export const getVendorOffers = async ({
  vendorId,
  query,
  $skip,
  $limit,
}: VendorOrdersQuery): Promise<OrdersQueryResult> => {
  return ApiClient.post(`vendor/${vendorId}/offers/search`, {
    query,
    $skip: Number($skip),
    $limit: Number($limit),
  });
};

export const getOffer = async (publicId: string): Promise<PublicOffer> =>
  ApiClient.get(`offer/new-checkout/${publicId}`, { queryParams: { includeEsDeal: true } });

export const updateOffer = async (
  { publicId, data }: UpdateOrderQuery,
  isSuperAdminOrPricingUpdateAdmin: boolean
): Promise<PublicOffer> => {
  const esDeal = data.esDeal;

  return ApiClient.patch(`offer/pricing/${publicId}`, {
    aioPackageName: data.aioPackageName,
    ownershipType: data.ownershipType,
    leasingPeriod: data.leasingPeriod,
    leasingMileage: data.leasingMileage,
    canton: data.canton,
    nationality: data.nationality,
    ageCategory: data.ageCategory,
    downPayment: data.downPayment,
    initialCarPrice: data.initialCarPrice,
    carPrice: esDeal.price,
    listPrice: esDeal.listPrice,
    engine: esDeal.engine,
    licensingDate: esDeal.licensingDateRaw,
    km: esDeal.km,
    modelYear: esDeal.modelYear,
    skipOfferStepCheck: true,
    skipContractSignedCheck: data.skipContractSignedCheck,
    skipResidualValueAndAioPricingCalculation: data.skipResidualValueAndAioPricingCalculation,
    promoCode: data.promoCode,
    offerType: data.offerType,
    oemName: data.offerType === OfferType.OEM ? OEMName.TESLA : undefined,
    nominalInterestRateOriginal: isSuperAdminOrPricingUpdateAdmin ? data.nominalInterestRateOriginal : undefined,
    interestRateDiscount: isSuperAdminOrPricingUpdateAdmin ? data.interestRateDiscount : undefined,
    privateCreditFinancingAmount: data.privateCreditFinancingAmount,
    privateCreditDuration: data.privateCreditDuration,
  });
};

export const getCustomerFiles = async ({ customerId, offerId, fileTypes }: GetCustomerFilesRequest): Promise<any> => {
  return ApiClient.get(
    `customer-files/${customerId}/${offerId}/files?${createRequestQueryArray('fileTypes', fileTypes)}`
  );
};

export const uploadFile = ({
  customerId,
  offerId,
  formData,
  fileType,
  isAdminFileType,
  uploadUpdateFileTypeSlug,
  isMostUsedDocument = false,
}: UploadCustomerFileRequest): Promise<{ location?: string }> => {
  return ApiClient.post(
    `customer-files/${isAdminFileType ? 'admin/' : ''}${customerId}/${offerId}/file/${
      uploadUpdateFileTypeSlug || ''
    }?fileType=${fileType}&returnLocationHeader=${isMostUsedDocument}`,
    formData,
    { isFormData: true }
  );
};

export const deleteFile = ({ customerId, offerId, fileType }: DeleteCustomerFileRequest): Promise<any> => {
  return ApiClient.delete(`customer-files/${customerId}/${offerId}/file/${fileType}`);
};

export const deactivate = async (publicId: string) =>
  ApiClient.patch(`offer/deactivate/${publicId}`, undefined, {
    noBody: true,
  });

export const reactivate = async (publicId: string) => {
  return ApiClient.patch(`offer/reactivate/${publicId}`, undefined, {
    noBody: true,
  });
};
