import { Nationality } from 'types';

export const nationalityTranslations = {
  [Nationality.AFGHANISTAN]: 'Afghanistan',
  [Nationality.ÅLAND_ISLANDS]: 'Åland Islands',
  [Nationality.ALBANIA]: 'Albania',
  [Nationality.ALGERIA]: 'Algeria',
  [Nationality.AMERICAN_SAMOA]: 'American Samoa',
  [Nationality.ANDORRA]: 'Andorra',
  [Nationality.ANGOLA]: 'Angola',
  [Nationality.ANGUILLA]: 'Anguilla',
  [Nationality.ANTARCTICA]: 'Antarctica',
  [Nationality.ANTIGUA_AND_BARBUDA]: 'Antigua and Barbuda',
  [Nationality.ARGENTINA]: 'Argentina',
  [Nationality.ARMENIA]: 'Armenia',
  [Nationality.ARUBA]: 'Aruba',
  [Nationality.AUSTRALIA]: 'Australia',
  [Nationality.AUSTRIA]: 'Austria',
  [Nationality.AZERBAIJAN]: 'Azerbaijan',
  [Nationality.BAHAMAS]: 'Bahamas',
  [Nationality.BAHRAIN]: 'Bahrain',
  [Nationality.BANGLADESH]: 'Bangladesh',
  [Nationality.BARBADOS]: 'Barbados',
  [Nationality.BELARUS]: 'Belarus',
  [Nationality.BELGIUM]: 'Belgium',
  [Nationality.BELIZE]: 'Belize',
  [Nationality.BENIN]: 'Benin',
  [Nationality.BERMUDA]: 'Bermuda',
  [Nationality.BHUTAN]: 'Bhutan',
  [Nationality.BOLIVIA]: 'Bolivia',
  [Nationality.BOSNIA_AND_HERZEGOVINA]: 'Bosnia and Herzegovina',
  [Nationality.BOTSWANA]: 'Botswana',
  [Nationality.BOUVET_ISLAND]: 'Bouvet Island',
  [Nationality.BRAZIL]: 'Brazil',
  [Nationality.BRITISH_INDIAN_OCEAN_TERRITORY]: 'British Indian Ocean Territory',
  [Nationality.BRITISH_VIRGIN_ISLANDS]: 'British Virgin Islands',
  [Nationality.BRUNEI]: 'Brunei',
  [Nationality.BULGARIA]: 'Bulgaria',
  [Nationality.BURKINA_FASO]: 'Burkina Faso',
  [Nationality.BURUNDI]: 'Burundi',
  [Nationality.CAMBODIA]: 'Cambodia',
  [Nationality.CAMEROON]: 'Cameroon',
  [Nationality.CANADA]: 'Canada',
  [Nationality.CAPE_VERDE]: 'Cape Verde',
  [Nationality.CARIBBEAN_NETHERLANDS]: 'Caribbean Netherlands',
  [Nationality.CAYMAN_ISLANDS]: 'Cayman Islands',
  [Nationality.CENTRAL_AFRICAN_REPUBLIC]: 'Central African Republic',
  [Nationality.CHAD]: 'Chad',
  [Nationality.CHILE]: 'Chile',
  [Nationality.CHINA]: 'China',
  [Nationality.CHRISTMAS_ISLAND]: 'Christmas Island',
  [Nationality.COCOS_KEELING_ISLANDS]: 'Cocos (Keeling) Islands',
  [Nationality.COLOMBIA]: 'Colombia',
  [Nationality.COMOROS]: 'Comoros',
  [Nationality.CONGO]: 'Congo',
  [Nationality.COOK_ISLANDS]: 'Cook Islands',
  [Nationality.COSTA_RICA]: 'Costa Rica',
  [Nationality.COTE_DIVOIRE]: "Cote d'Ivoire",
  [Nationality.CROATIA]: 'Croatia',
  [Nationality.CUBA]: 'Cuba',
  [Nationality.CURAÇAO]: 'Curaçao',
  [Nationality.CYPRUS]: 'Cyprus',
  [Nationality.CZECH_REPUBLIC]: 'Czech Republic',
  [Nationality.DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: 'Democratic Republic of the Congo',
  [Nationality.DENMARK]: 'Denmark',
  [Nationality.DJIBOUTI]: 'Djibouti',
  [Nationality.DOMINICA]: 'Dominica',
  [Nationality.DOMINICAN_REPUBLIC]: 'Dominican Republic',
  [Nationality.EAST_TIMOR]: 'East Timor',
  [Nationality.ECUADOR]: 'Ecuador',
  [Nationality.EGYPT]: 'Egypt',
  [Nationality.EL_SALVADOR]: 'El Salvador',
  [Nationality.EQUATORIAL_GUINEA]: 'Equatorial Guinea',
  [Nationality.ERITREA]: 'Eritrea',
  [Nationality.ESTONIA]: 'Estonia',
  [Nationality.ETHIOPIA]: 'Ethiopia',
  [Nationality.FALKLAND_ISLANDS]: 'Falkland Islands',
  [Nationality.FAROE_ISLANDS]: 'Faroe Islands',
  [Nationality.FIJI]: 'Fiji',
  [Nationality.FINLAND]: 'Finland',
  [Nationality.FRANCE]: 'France',
  [Nationality.FRENCH_GUIANA]: 'French Guiana',
  [Nationality.FRENCH_POLYNESIA]: 'French Polynesia',
  [Nationality.FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS]: 'French Southern and Antarctic Lands',
  [Nationality.GABON]: 'Gabon',
  [Nationality.GAMBIA]: 'Gambia',
  [Nationality.GEORGIA]: 'Georgia',
  [Nationality.GERMANY]: 'Germany',
  [Nationality.GHANA]: 'Ghana',
  [Nationality.GIBRALTAR]: 'Gibraltar',
  [Nationality.GREECE]: 'Greece',
  [Nationality.GREENLAND]: 'Greenland',
  [Nationality.GRENADA]: 'Grenada',
  [Nationality.GUADELOUPE]: 'Guadeloupe',
  [Nationality.GUAM]: 'Guam',
  [Nationality.GUATEMALA]: 'Guatemala',
  [Nationality.GUERNSEY]: 'Guernsey',
  [Nationality.GUINEA]: 'Guinea',
  [Nationality.GUINEA_BISSAU]: 'Guinea-Bissau',
  [Nationality.GUYANA]: 'Guyana',
  [Nationality.HAITI]: 'Haiti',
  [Nationality.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'Heard Island and McDonald Islands',
  [Nationality.HONDURAS]: 'Honduras',
  [Nationality.HONG_KONG]: 'Hong Kong',
  [Nationality.HUNGARY]: 'Hungary',
  [Nationality.ICELAND]: 'Iceland',
  [Nationality.INDIA]: 'India',
  [Nationality.INDONESIA]: 'Indonesia',
  [Nationality.IRAN]: 'Iran',
  [Nationality.IRAQ]: 'Iraq',
  [Nationality.IRELAND]: 'Ireland',
  [Nationality.ISLE_OF_MAN]: 'Isle of Man',
  [Nationality.ISRAEL]: 'Israel',
  [Nationality.ITALY]: 'Italy',
  [Nationality.JAMAICA]: 'Jamaica',
  [Nationality.JAPAN]: 'Japan',
  [Nationality.JERSEY]: 'Jersey',
  [Nationality.JORDAN]: 'Jordan',
  [Nationality.KAZAKHSTAN]: 'Kazakhstan',
  [Nationality.KENYA]: 'Kenya',
  [Nationality.KIRIBATI]: 'Kiribati',
  [Nationality.KUWAIT]: 'Kuwait',
  [Nationality.KYRGYZSTAN]: 'Kyrgyzstan',
  [Nationality.LAOS]: 'Laos',
  [Nationality.LATVIA]: 'Latvia',
  [Nationality.LEBANON]: 'Lebanon',
  [Nationality.LESOTHO]: 'Lesotho',
  [Nationality.LIBERIA]: 'Liberia',
  [Nationality.LIBYA]: 'Libya',
  [Nationality.LIECHTENSTEIN]: 'Liechtenstein',
  [Nationality.LITHUANIA]: 'Lithuania',
  [Nationality.LUXEMBOURG]: 'Luxembourg',
  [Nationality.MACAU]: 'Macau',
  [Nationality.MACEDONIA_FYROM]: 'Macedonia (FYROM)',
  [Nationality.MADAGASCAR]: 'Madagascar',
  [Nationality.MALAWI]: 'Malawi',
  [Nationality.MALAYSIA]: 'Malaysia',
  [Nationality.MALDIVES]: 'Maldives',
  [Nationality.MALI]: 'Mali',
  [Nationality.MALTA]: 'Malta',
  [Nationality.MARSHALL_ISLANDS]: 'Marshall Islands',
  [Nationality.MARTINIQUE]: 'Martinique',
  [Nationality.MAURITANIA]: 'Mauritania',
  [Nationality.MAURITIUS]: 'Mauritius',
  [Nationality.MAYOTTE]: 'Mayotte',
  [Nationality.MEXICO]: 'Mexico',
  [Nationality.MICRONESIA]: 'Micronesia',
  [Nationality.MOLDOVA]: 'Moldova',
  [Nationality.MONACO]: 'Monaco',
  [Nationality.MONGOLIA]: 'Mongolia',
  [Nationality.MONTENEGRO]: 'Montenegro',
  [Nationality.MONTSERRAT]: 'Montserrat',
  [Nationality.MOROCCO]: 'Morocco',
  [Nationality.MOZAMBIQUE]: 'Mozambique',
  [Nationality.MYANMAR_BURMA]: 'Myanmar (Burma)',
  [Nationality.NAMIBIA]: 'Namibia',
  [Nationality.NAURU]: 'Nauru',
  [Nationality.NEPAL]: 'Nepal',
  [Nationality.NETHERLANDS]: 'Netherlands',
  [Nationality.NETHERLANDS_ANTILLES]: 'Netherlands Antilles',
  [Nationality.NEW_CALEDONIA]: 'New Caledonia',
  [Nationality.NEW_ZEALAND]: 'New Zealand',
  [Nationality.NICARAGUA]: 'Nicaragua',
  [Nationality.NIGER]: 'Niger',
  [Nationality.NIGERIA]: 'Nigeria',
  [Nationality.NIUE]: 'Niue',
  [Nationality.NORFOLK_ISLAND]: 'Norfolk Island',
  [Nationality.NORTH_KOREA]: 'North Korea',
  [Nationality.NORTHERN_MARIANA_ISLANDS]: 'Northern Mariana Islands',
  [Nationality.NORWAY]: 'Norway',
  [Nationality.OMAN]: 'Oman',
  [Nationality.PAKISTAN]: 'Pakistan',
  [Nationality.PALAU]: 'Palau',
  [Nationality.PALESTINE]: 'Palestine',
  [Nationality.PANAMA]: 'Panama',
  [Nationality.PAPUA_NEW_GUINEA]: 'Papua New Guinea',
  [Nationality.PARAGUAY]: 'Paraguay',
  [Nationality.PERU]: 'Peru',
  [Nationality.PHILIPPINES]: 'Philippines',
  [Nationality.PITCAIRN_ISLANDS]: 'Pitcairn Islands',
  [Nationality.POLAND]: 'Poland',
  [Nationality.PORTUGAL]: 'Portugal',
  [Nationality.PUERTO_RICO]: 'Puerto Rico',
  [Nationality.QATAR]: 'Qatar',
  [Nationality.RÉUNION]: 'Réunion',
  [Nationality.ROMANIA]: 'Romania',
  [Nationality.RUSSIA]: 'Russia',
  [Nationality.RWANDA]: 'Rwanda',
  [Nationality.SAINT_BARTHÉLEMY]: 'Saint Barthélemy',
  [Nationality.SAINT_HELENA]: 'Saint Helena',
  [Nationality.SAINT_KITTS_AND_NEVIS]: 'Saint Kitts and Nevis',
  [Nationality.SAINT_LUCIA]: 'Saint Lucia',
  [Nationality.SAINT_MARTIN]: 'Saint Martin',
  [Nationality.SAINT_PIERRE_AND_MIQUELON]: 'Saint Pierre and Miquelon',
  [Nationality.SAINT_VINCENT_AND_THE_GRENADINES]: 'Saint Vincent and the Grenadines',
  [Nationality.SAMOA]: 'Samoa',
  [Nationality.SAN_MARINO]: 'San Marino',
  [Nationality.SAO_TOME_AND_PRINCIPE]: 'Sao Tome and Principe',
  [Nationality.SAUDI_ARABIA]: 'Saudi Arabia',
  [Nationality.SENEGAL]: 'Senegal',
  [Nationality.SERBIA]: 'Serbia',
  [Nationality.SEYCHELLES]: 'Seychelles',
  [Nationality.SIERRA_LEONE]: 'Sierra Leone',
  [Nationality.SINGAPORE]: 'Singapore',
  [Nationality.SINT_MAARTEN]: 'Sint Maarten',
  [Nationality.SLOVAKIA]: 'Slovakia',
  [Nationality.SLOVENIA]: 'Slovenia',
  [Nationality.SOLOMON_ISLANDS]: 'Solomon Islands',
  [Nationality.SOMALIA]: 'Somalia',
  [Nationality.SOUTH_AFRICA]: 'South Africa',
  [Nationality.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'South Georgia and the South Sandwich Islands',
  [Nationality.SOUTH_KOREA]: 'South Korea',
  [Nationality.SOUTH_SUDAN]: 'South Sudan',
  [Nationality.SPAIN]: 'Spain',
  [Nationality.SRI_LANKA]: 'Sri Lanka',
  [Nationality.SUDAN]: 'Sudan',
  [Nationality.SURINAME]: 'Suriname',
  [Nationality.SVALBARD_AND_JAN_MAYEN]: 'Svalbard and Jan Mayen',
  [Nationality.SWAZILAND]: 'Swaziland',
  [Nationality.SWEDEN]: 'Sweden',
  [Nationality.SWITZERLAND]: 'Switzerland',
  [Nationality.SYRIA]: 'Syria',
  [Nationality.TAIWAN]: 'Taiwan',
  [Nationality.TAJIKISTAN]: 'Tajikistan',
  [Nationality.TANZANIA]: 'Tanzania',
  [Nationality.THAILAND]: 'Thailand',
  [Nationality.TOGO]: 'Togo',
  [Nationality.TOKELAU]: 'Tokelau',
  [Nationality.TONGA]: 'Tonga',
  [Nationality.TRINIDAD_AND_TOBAGO]: 'Trinidad and Tobago',
  [Nationality.TUNISIA]: 'Tunisia',
  [Nationality.TURKEY]: 'Turkey',
  [Nationality.TURKMENISTAN]: 'Turkmenistan',
  [Nationality.TURKS_AND_CAICOS_ISLANDS]: 'Turks and Caicos Islands',
  [Nationality.TUVALU]: 'Tuvalu',
  [Nationality.U_S_VIRGIN_ISLANDS]: 'U.S. Virgin Islands',
  [Nationality.UGANDA]: 'Uganda',
  [Nationality.UKRAINE]: 'Ukraine',
  [Nationality.UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
  [Nationality.UNITED_KINGDOM]: 'United Kingdom',
  [Nationality.UNITED_STATES]: 'United States',
  [Nationality.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'United States Minor Outlying Islands',
  [Nationality.URUGUAY]: 'Uruguay',
  [Nationality.UZBEKISTAN]: 'Uzbekistan',
  [Nationality.VANUATU]: 'Vanuatu',
  [Nationality.VATICAN_CITY]: 'Vatican City',
  [Nationality.VENEZUELA]: 'Venezuela',
  [Nationality.VIETNAM]: 'Vietnam',
  [Nationality.WALLIS_AND_FUTUNA]: 'Wallis and Futuna',
  [Nationality.WESTERN_SAHARA]: 'Western Sahara',
  [Nationality.YEMEN]: 'Yemen',
  [Nationality.ZAMBIA]: 'Zambia',
  [Nationality.ZIMBABWE]: 'Zimbabwe',
};
