import { MAX_LOAN_AMOUNT, MIN_LOAN_AMOUNT } from 'constants/constants';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { OfferType, OwnershipType, PublicOfferPricing } from 'types';
import { DD_MM_YYYY, getMonthsFromToday } from 'utils/date';
import { transformEmptyToNull } from 'utils/form';
import * as Yup from 'yup';
import { messages } from './EditOrderPage.translations';

const isTeslaOEM = (ownershipType: OwnershipType, offerType: OfferType) =>
  offerType === OfferType.OEM && ownershipType !== OwnershipType.PRIVATE_CREDIT;

export const OrderValidationShema = ({ formatMessage }: IntlShape) => {
  const LEASING_OWNERSHIP_TYPES = [OwnershipType.LEASING, OwnershipType.LEASING_ONLY];

  const shapeObj = {
    ownershipType: Yup.string(),
    canton: Yup.string().nullable().transform(transformEmptyToNull),
    leasingPeriod: Yup.number().nullable().transform(transformEmptyToNull),
    leasingMileage: Yup.number().nullable().transform(transformEmptyToNull),
    downPayment: Yup.number()
      .transform(transformEmptyToNull)
      .when('ownershipType', {
        is: LEASING_OWNERSHIP_TYPES,
        then: Yup.number().required(formatMessage(messages.EditOrderPageRequired)).positive().min(0),
      })
      .nullable(),
    promoCode: Yup.string().nullable().transform(transformEmptyToNull),
    skipContractSignedCheck: Yup.boolean().nullable(),
    skipResidualValueAndAioPricingCalculation: Yup.boolean().nullable(),
    interestRateNominalConfirmed: Yup.number().nullable().transform(transformEmptyToNull),
    interestRateDiscount: Yup.number().nullable().transform(transformEmptyToNull),
    nominalInterestRateOriginal: Yup.number().nullable().transform(transformEmptyToNull),
    privateCreditFinancingAmount: Yup.number()
      .nullable()
      .transform(transformEmptyToNull)
      .when('ownershipType', {
        is: OwnershipType.PRIVATE_CREDIT,
        then: Yup.number()
          .nullable()
          .required(formatMessage(messages.EditOrderPageRequired))
          .positive()
          .min(MIN_LOAN_AMOUNT)
          .max(MAX_LOAN_AMOUNT),
      }),
    privateCreditDuration: Yup.number()
      .nullable()
      .transform(transformEmptyToNull)
      .when('ownershipType', {
        is: OwnershipType.PRIVATE_CREDIT,
        then: Yup.number().required(formatMessage(messages.EditOrderPageRequired)).positive(),
      }),
    initialCarPrice: Yup.number()
      .nullable()
      .transform(transformEmptyToNull)
      .when(['ownershipType', 'offerType'], {
        is: isTeslaOEM,
        then: Yup.number().required(formatMessage(messages.EditOrderPageRequired)),
      }),
    esDeal: Yup.object().shape({
      modelYear: Yup.number().nullable().transform(transformEmptyToNull).min(1900),
      engine: Yup.string().nullable().transform(transformEmptyToNull),
      licensingDateRaw: Yup.string()
        .nullable()
        .transform(transformEmptyToNull)
        .test(
          '60-months-from-today',
          formatMessage(messages.EditOrderPageLicensingDateShouldBeLaterThan, {
            value: moment(getMonthsFromToday(60)).format(DD_MM_YYYY),
          }),
          function (this, value) {
            if (!value) return true;

            return !moment(value).isBefore(getMonthsFromToday(60));
          }
        ),
      km: Yup.number()
        .nullable()
        .transform(transformEmptyToNull)
        .required(formatMessage(messages.EditOrderPageRequired))
        .min(0),
      listPrice: Yup.number().nullable().transform(transformEmptyToNull),
      price: Yup.number()
        .nullable()
        .transform(transformEmptyToNull)
        .when(['ownershipType', 'offerType'], {
          is: isTeslaOEM,
          otherwise: Yup.number().required(formatMessage(messages.EditOrderPageRequired)).positive(),
        }),
    }),
  } as Record<keyof Partial<PublicOfferPricing>, any>;
  return Yup.object().shape(shapeObj);
};
