import { IntlShape } from 'react-intl';
import {
  AgeCategory,
  LeasingMileage,
  LeasingPeriod,
  Nationality,
  OwnershipType,
  PublicOffer,
  SelectOption,
} from 'types';
import { nationalityTranslations } from 'utils/nationality';
import { messages } from '../FormContent.translations';

export const getAgeCategoryOptions = (formatMessage: IntlShape['formatMessage']): Array<SelectOption> => [
  { label: formatMessage(messages.OfferOverviewFormUnder25), value: AgeCategory.UNDER_25 },
  { label: formatMessage(messages.OfferOverviewForm25OrAbove), value: AgeCategory.ABOVE_25 },
];

export const getNationalityOptions = (): Array<SelectOption> =>
  Object.values(Nationality).map((nationality) => ({
    label: nationalityTranslations[nationality],
    value: nationality,
  }));

export const getOwnershipTypeOptions = (order: PublicOffer, formatMessage: any): Array<SelectOption> => {
  const result = [
    {
      label: formatMessage(messages.OfferOverviewFormLeasingPlusAllInOne),
      value: OwnershipType.LEASING,
    },
    {
      label: formatMessage(messages.OfferOverviewFormLeasingOnly),
      value: OwnershipType.LEASING_ONLY,
    },
  ];

  if (order?.ownershipType === OwnershipType.PRIVATE_CREDIT) {
    result.push({
      label: formatMessage(messages.OfferOverviewFormPrivateCredit),
      value: OwnershipType.PRIVATE_CREDIT,
    });
  }

  if (order?.ownershipType === OwnershipType.BUSINESS_LEASING) {
    result.push({
      label: formatMessage(messages.OfferOverviewFormBusinessLeasing),
      value: OwnershipType.BUSINESS_LEASING,
    });
  }

  return result;
};

export const getLeasingPeriodOptions = (
  formatMessage: any,
  allowedLeasingPeriods: LeasingPeriod[]
): Array<SelectOption> => {
  if (!allowedLeasingPeriods) return [];

  return allowedLeasingPeriods.map((period) => ({
    label: formatMessage(messages.OfferOverviewNoOfMonths, { noOfMonths: period }),
    value: period.toString(),
  }));
};

export const getLeasingMileageOptions = (
  formatMessage: any,
  lang: string,
  allowedLeasingMileages: LeasingMileage[]
): Array<SelectOption> => {
  if (!allowedLeasingMileages) return [];

  return allowedLeasingMileages.map((mileage) => ({
    label: formatMessage(messages.OfferOverviewNoOfKM, { noOfKm: Number(mileage).toLocaleString(lang) }),
    value: mileage.toString(),
  }));
};
